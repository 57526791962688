import uniqueId from 'lodash.uniqueid';
import React, { useContext, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useFormContext } from 'react-hook-form';
import LocalizationContext from '../../../context/LocalizationContext';
import { getAttendeeType } from '../../../utils/AccountUtils';
import { postalCodeValidator, ZIP_CODE_MAX } from '../../../utils/FormUtils';
import { isInternal } from '../../../utils/InternalUtils';
import {
    AttendeeType,
    AttributeType,
    DataCollectionAssociation,
    ParticipantInfo,
} from '../../../utils/types';
import { ACCESS_CODE_LENGTH, ACCESS_CODE_LENGTH_WITH_SPACES } from '../../accessCodes/AccessCodes';
import GenericField from './GenericField';
import MailingAddressFields from './MailingAddressFields';
import OptInCheckbox from './OptInCheckbox';

export type DataCollectionFieldsProps = {
    formControlReferencePrefix: string;
    userId: string;
    nickname: string;
    participantId?: string;
    participantInfo?: ParticipantInfo;
    birthday: string | null;
    isRegistrant?: boolean;
    dataCollection?: Array<DataCollectionAssociation> | null;
};

/**
 * Component used to take the list of dataCollection items and turn them into their appropriate form component.
 * Note that each dataCollection item is associated with a particular AttendeeType. So we only display the
 * appropriate dataCollection item based on the participants birthday (child or adult) or if they are the
 * host of the party.
 *
 * NOTE: "AttendeeType.HOST" does NOT inherit "AttendeeType.ADULT" data collection items.
 *
 * @param formControlReferencePrefix - unique reference used for react-hook-form
 * @param userId - userId (Nintendo Account ID) of the logged-in user
 * @param nickname - Nintendo Account nickname, used for aria-label to help identify who the field is for
 * @param participantInfo - optional ParticipantInfo object
 * @param participantId - optional Participant ID (GPS account ID) of the user
 * @param dataCollection - list of DataCollectionAssociation objects
 * @param birthday - birthday for this userId
 * @param isRegistrant - boolean is this userId the one registering?
 * @constructor
 */
const DataCollectionFields = ({
    formControlReferencePrefix,
    userId,
    nickname,
    participantInfo,
    participantId,
    dataCollection,
    birthday,
    isRegistrant = false,
}: DataCollectionFieldsProps) => {
    const localizedStrings = useContext(LocalizationContext);
    const { setValue } = useFormContext();

    const [uniqueKey] = useState(uniqueId(`${userId}_${formControlReferencePrefix}`));

    let attendeeType: AttendeeType = isRegistrant ? AttendeeType.HOST : getAttendeeType(birthday);
    if (isInternal(localizedStrings)) {
        attendeeType = AttendeeType.HOST;
    }

    const dataCollectionItems = dataCollection || [];
    const validAttributes: Array<DataCollectionAssociation> = dataCollectionItems?.filter(
        (dataItem) => {
            return dataItem.data_collection.attendee_type === attendeeType;
        },
    );

    const attributeOrder = Object.values(AttributeType);
    const sortedAttributes = validAttributes.sort((a, b) => {
        return (
            attributeOrder.indexOf(a.data_collection.attribute_type) -
            attributeOrder.indexOf(b.data_collection.attribute_type)
        );
    });

    const dataCollectionForm = sortedAttributes.map((dataCollectionItem) => {
        const formControlReference = `${formControlReferencePrefix}.${dataCollectionItem.data_collection.attribute_type}`;
        let field = null;

        switch (dataCollectionItem.data_collection.attribute_type) {
            case AttributeType.FULL_NAME:
                field = (
                    <>
                        <Col md={6}>
                            <GenericField
                                nickname={nickname}
                                tooltip={dataCollectionItem.data_collection.tooltip}
                                isRequired={dataCollectionItem.data_collection.require_response}
                                label={localizedStrings.events.dataAttribute.FIRST_NAME}
                                defaultValue={
                                    participantInfo ? participantInfo.FIRST_NAME : undefined
                                }
                                placeholder={localizedStrings.events.dataAttribute.FIRST_NAME}
                                formControlReference={`${formControlReferencePrefix}.${AttributeType.FIRST_NAME}`}
                            />
                        </Col>
                        <Col md={6}>
                            <GenericField
                                nickname={nickname}
                                isRequired={dataCollectionItem.data_collection.require_response}
                                label={localizedStrings.events.dataAttribute.LAST_NAME}
                                defaultValue={
                                    participantInfo ? participantInfo.LAST_NAME : undefined
                                }
                                placeholder={localizedStrings.events.dataAttribute.LAST_NAME}
                                formControlReference={`${formControlReferencePrefix}.${AttributeType.LAST_NAME}`}
                            />
                        </Col>
                    </>
                );
                break;
            case AttributeType.FIRST_NAME:
                field = (
                    <Col xs={12} md={6}>
                        <GenericField
                            nickname={nickname}
                            tooltip={dataCollectionItem.data_collection.tooltip}
                            isRequired={dataCollectionItem.data_collection.require_response}
                            label={localizedStrings.events.dataAttribute.FIRST_NAME}
                            defaultValue={participantInfo ? participantInfo.FIRST_NAME : undefined}
                            placeholder={localizedStrings.events.dataAttribute.FIRST_NAME}
                            formControlReference={formControlReference}
                        />
                    </Col>
                );
                break;
            case AttributeType.LAST_NAME:
                field = (
                    <Col md={6}>
                        <GenericField
                            nickname={nickname}
                            tooltip={dataCollectionItem.data_collection.tooltip}
                            isRequired={dataCollectionItem.data_collection.require_response}
                            label={localizedStrings.events.dataAttribute.LAST_NAME}
                            defaultValue={participantInfo ? participantInfo.LAST_NAME : undefined}
                            placeholder={localizedStrings.events.dataAttribute.LAST_NAME}
                            formControlReference={formControlReference}
                        />
                    </Col>
                );
                break;
            case AttributeType.POSTAL_CODE: {
                field = (
                    <Col md={6}>
                        <GenericField
                            nickname={nickname}
                            tooltip={dataCollectionItem.data_collection.tooltip}
                            isRequired={dataCollectionItem.data_collection.require_response}
                            label={localizedStrings.events.dataAttribute.MAILING_ADDRESS.zipCode}
                            defaultValue={participantInfo ? participantInfo.POSTAL_CODE : undefined}
                            placeholder={
                                localizedStrings.events.dataAttribute.MAILING_ADDRESS.zipCode
                            }
                            formControlReference={formControlReference}
                            validator={postalCodeValidator}
                            maxLength={ZIP_CODE_MAX}
                        />
                    </Col>
                );
                break;
            }
            case AttributeType.PHONE:
                field = (
                    <Col md={6}>
                        <GenericField
                            nickname={nickname}
                            tooltip={dataCollectionItem.data_collection.tooltip}
                            isRequired={dataCollectionItem.data_collection.require_response}
                            label={localizedStrings.events.dataAttribute.PHONE}
                            defaultValue={participantInfo ? participantInfo.PHONE : undefined}
                            placeholder={localizedStrings.events.dataAttribute.PHONE}
                            formControlReference={formControlReference}
                        />
                    </Col>
                );
                break;
            case AttributeType.US_ADDRESS:
                field = (
                    <MailingAddressFields
                        dataCollectionItem={dataCollectionItem}
                        formControlReferencePrefix={formControlReferencePrefix}
                        participantInfo={participantInfo}
                    />
                );
                break;
            case AttributeType.OPT_IN:
                field = participantId ? (
                    <Col md={6}>
                        <OptInCheckbox
                            tooltip={dataCollectionItem.data_collection.tooltip}
                            checkboxLabel={localizedStrings.events.dataAttribute.OPT_IN}
                            formControlReference={formControlReference}
                        />
                    </Col>
                ) : null;
                break;
            case AttributeType.ACCESS_CODE:
                /**
                 * For registrants, the defaultValue should be filled in and not editable. This is to prevent
                 * the registrant from entering a new access code, which may point to a completely different
                 * event gate.
                 *
                 * If this is the registrant than disable the field, make it not required, and remove validation
                 */
                field = (
                    <Col xs={12} md={6}>
                        <GenericField
                            onChange={(event) => {
                                const value = event.target.value.replace(/\s/g, '').toUpperCase();
                                setValue(formControlReference, value);
                            }}
                            maxLength={ACCESS_CODE_LENGTH_WITH_SPACES}
                            readOnly={isRegistrant}
                            nickname={nickname}
                            tooltip={dataCollectionItem.data_collection.tooltip}
                            isRequired={
                                isRegistrant
                                    ? false
                                    : dataCollectionItem.data_collection.require_response
                            }
                            label={localizedStrings.events.dataAttribute.ACCESS_CODE}
                            defaultValue={participantInfo ? participantInfo.ACCESS_CODE : undefined}
                            placeholder={
                                localizedStrings.events.details.accessCode.inputPlaceHolder
                            }
                            formControlReference={formControlReference}
                            validator={
                                isRegistrant
                                    ? undefined
                                    : (value: string) => {
                                          return (
                                              value.replace(/\s/g, '').length === ACCESS_CODE_LENGTH
                                          );
                                      }
                            }
                        />
                    </Col>
                );
                break;
            default:
                return null;
        }
        return <Row key={uniqueKey}>{field}</Row>;
    });

    return <>{dataCollectionForm}</>;
};

export default DataCollectionFields;
