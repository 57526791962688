import React, { useState, useEffect } from 'react';
import keyBy from 'lodash.keyby';
import { Config } from '../utils/types';

export type AsyncData<T> = {
    value: T;
    loading: boolean;
    error: boolean;
    refresh: () => void;
};

/**
 * Custom hook for handling state, fetch and error handling for async function.
 * @param asyncFunc {Function | null} - Function for getting the requested async data. If null, it will return the initial state.
 * @param defaultState - The default state for the async data
 * @param refreshProps - Any additional props for refreshing async data with
 *
 * to determine if the initial loading state should be set to true or not. Using this optional field will force the value to true/false.
 */
export const useFetchState = <T>(
    asyncFunc: Function | null,
    defaultState: T,
    refreshProps?: Array<string>,
): AsyncData<T> => {
    const [asyncState, setAsyncState] = useState(defaultState);
    const [isLoading, setIsLoading] = useState(asyncFunc !== null);
    const [error, setError] = useState(false);
    const [refresh, setRefresh] = useState(0);

    const effectRefresh = refreshProps ? [refresh, ...refreshProps] : [refresh];

    useEffect(() => {
        const getData = async () => {
            if (asyncFunc) {
                setIsLoading(true);
                const newData = await asyncFunc();

                if (newData) {
                    setAsyncState(newData);
                } else {
                    setError(true);
                }
                setIsLoading(false);
            }
        };
        getData();
    }, effectRefresh);

    return {
        value: asyncState,
        loading: isLoading,
        error,
        refresh: () => setRefresh(refresh + 1),
    };
};

/**
 * Custom hook for performing some function after the delay time expires
 * https://facebook.github.io/react-native/docs/timers
 */
export const useTimeout = (
    asyncFunction: Function,
    callback: Function | null,
    delay: number,
): { refresh: () => void } => {
    const [refreshProp, setRefreshProp] = useState(0);

    const refresh = () => {
        setRefreshProp(refreshProp + 1);
    };

    useEffect(() => {
        const newAsyncFunction = async () => {
            const data = await asyncFunction();
            if (callback) {
                await callback(data);
            }
        };

        let newTimeout: number | null = null;
        if (delay > 0) {
            newTimeout = window.setTimeout(() => {
                newAsyncFunction();
            }, delay);
        }

        return () => {
            if (newTimeout) {
                clearTimeout(newTimeout);
            }
        };
    }, [refreshProp, delay]);

    return {
        refresh,
    };
};

/**
 * Use this effect to show a browser native confirmation dialog when the user tries to refresh or leave the site.
 * This allows the user to cancel or confirm before the browser redirects the user and loses context.
 */
export const useWindowUnloadEffect = () => {
    useEffect(() => {
        const beforeUnloadHandler = (event: BeforeUnloadEvent) => {
            event.preventDefault();
            event.returnValue = true;
        };

        window.addEventListener('beforeunload', beforeUnloadHandler);

        return () => {
            window.removeEventListener('beforeunload', beforeUnloadHandler);
        };
    }, []);
};

/**
 * Same as useWindowUnloadEffect except you can pass in your own callback method
 * @param unloadHandler custom handler, called when an 'beforeunload' event occurs
 */
export const useWindowUnloadWithCallbackEffect = (
    unloadHandler: (event: BeforeUnloadEvent | null) => void,
) => {
    useEffect(() => {
        window.addEventListener('beforeunload', unloadHandler);

        return () => {
            window.removeEventListener('beforeunload', unloadHandler);
        };
    }, []);
};

export const useToast = (processingFunction: () => void, initialState?: boolean) => {
    if (initialState === undefined) {
        initialState = true;
    }

    const [needToast, setNeedToast] = useState(initialState);

    if (needToast) {
        processingFunction();
        setNeedToast(false);
    }
};

/**
 * Custom hook for handling state while storing in browser session.
 * @param sessionStorageKey session storage key
 * @param initialState initial state to pass into useState
 */
export const useStateWithSessionStorage = (
    sessionStorageKey: string,
    initialState: string | (() => string),
): [string, React.Dispatch<string>] => {
    const [value, setValue] = useState(sessionStorage.getItem(sessionStorageKey) || initialState);
    useEffect(() => {
        if (value) {
            sessionStorage.setItem(sessionStorageKey, value);
        } else {
            sessionStorage.removeItem(sessionStorageKey);
        }
    }, [value]);
    return [value, setValue];
};

/**
 * Creates a map out of the config array and saves it into state. The cache is rebuilt
 * when the hook detects a change to the config array.
 * @param configs - Array of config items
 */
export const useConfigCache = (configs?: Array<Config>) => {
    const keyByConfigName = (config: Config) => {
        return config.name;
    };

    const [configMap, setConfigMap] = useState(keyBy(configs || [], keyByConfigName));

    // Use effect to update the config cache when the input changes
    useEffect(() => {
        setConfigMap(keyBy(configs || [], keyByConfigName));
    }, [configs]);

    return configMap;
};
