import React, { useContext } from 'react';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import {
    BaseTicket,
    DrawingTicket,
    MemberRoleType,
    MyTicketsRerouteState,
    Outcome,
    Party,
    PartyMember,
    TicketResource,
    TicketTypeResponse,
} from '../../../utils/types';
import ToastContent from '../../common/ToastContent';
import LocalizedDate from '../../common/LocalizedDate';
import { isDrawingTicket } from '../../../utils/typeGuards';
import LocalizationContext, {
    TickTockLocalizedStrings,
} from '../../../context/LocalizationContext';
import { checkIfSameDay } from '../../../utils/DateUtils';

const TEEN = 13;

export interface GroupedTicketsAndPasses {
    tickets: Map<string, Map<string, Array<DrawingTicket | BaseTicket>>>;
    warpPipePasses: Map<string, Map<string, Array<DrawingTicket | BaseTicket>>>;
    eventIdToNameMap: Map<string, string>;
}

/**
 * Parses the Array of DrawingTickets and BaseTickets response from GPS and groups them into 1 of the appropriate
 * buckets (tickets and warpPipePasses) as defined by the GroupedTicketsAndPasses type.
 *
 * Start dates are sorted in ascending order.
 *
 * Each property in the GroupedTicketsAndPasses are in the following format:
 *
 *
 *     <start_date>: {
 *         <event.event_id>: [
 *             { DrawingTicket | BaseTicket },
 *             ...
 *         ],
 *         ...
 *     },
 *     ...
 *
 *
 * @param data an array of DrawingTickets and BaseTickets
 * @return GroupedTicketsAndPasses sorted and grouped tickets and passes
 */
export const parsePassesAndTickets = (
    data: Array<DrawingTicket | BaseTicket> | undefined,
): GroupedTicketsAndPasses => {
    if (data === undefined) {
        return {
            tickets: new Map(),
            warpPipePasses: new Map(),
            eventIdToNameMap: new Map(),
        };
    }

    // Group responses into 2 buckets: tickets and warp pipe passes
    const tickets: Array<DrawingTicket | BaseTicket> = [];
    const warpPipePasses: Array<DrawingTicket | BaseTicket> = [];
    const eventIdToNameMap = sortPassesAndTickets(data, tickets, warpPipePasses);

    // For each of our groupings from above, group them by their appropriate start date
    const ticketsGroupedByDates = groupByStartDate(tickets, TicketTypeResponse.EVENT_GATE_TICKET);
    const warpPipePassesGroupedByDates = groupByStartDate(
        warpPipePasses,
        TicketTypeResponse.TIME_SLOT_TICKET,
    );

    // Sort each group by their dates, ascending
    const sortedTickets = new Map([...ticketsGroupedByDates.entries()].sort());
    const sortedWarpPipePasses = new Map([...warpPipePassesGroupedByDates.entries()].sort());

    // Group each sorted map by their event.event_id
    const ticketsGroupedByDatesAndEvents = groupByEventId(
        sortedTickets,
        TicketTypeResponse.EVENT_GATE_TICKET,
    );
    const wppGroupedByDatesAndEvents = groupByEventId(
        sortedWarpPipePasses,
        TicketTypeResponse.TIME_SLOT_TICKET,
    );

    return {
        tickets: ticketsGroupedByDatesAndEvents,
        warpPipePasses: wppGroupedByDatesAndEvents,
        eventIdToNameMap,
    };
};

/**
 * Sorts the data into 1 of 2 buckets; tickets or warpPipePasses based on their types
 *
 * @param data an array of DrawingTickets and BaseTickets to be sorted
 * @param tickets array of tickets to be filled in by this method
 * @param warpPipePasses array of warpPipePasses to be filled in by this method
 */
const sortPassesAndTickets = (
    data: Array<DrawingTicket | BaseTicket>,
    tickets: Array<DrawingTicket | BaseTicket>,
    warpPipePasses: Array<DrawingTicket | BaseTicket>,
): Map<string, string> => {
    const eventIdToNameMap: Map<string, string> = new Map();
    data.forEach((ticket: DrawingTicket | BaseTicket) => {
        if (isDrawingTicket(ticket)) {
            ticket.outcomes.forEach((drawingOutcome: Outcome) => {
                eventIdToNameMap.set(
                    drawingOutcome.details.meta_data.event_id.toString(),
                    drawingOutcome.details.meta_data.event_name,
                );
                if (isWarpPipePass(drawingOutcome.details.type)) {
                    warpPipePasses.push(ticket);
                } else {
                    tickets.push(ticket);
                }
            });
        } else if (isWarpPipePass(ticket.details.type)) {
            warpPipePasses.push(ticket);
            eventIdToNameMap.set(
                ticket.details.meta_data.event_id.toString(),
                ticket.details.meta_data.event_name,
            );
        } else {
            tickets.push(ticket);
            eventIdToNameMap.set(
                ticket.details.meta_data.event_id.toString(),
                ticket.details.meta_data.event_name,
            );
        }
    });
    return eventIdToNameMap;
};

/**
 * Groups the tickets by their appropriate start_date
 *
 * @param data an array of DrawingTickets and BaseTickets to be grouped
 * @param ticketType MyTicketTypeResponse value
 * @return Map where the key is the start date and value is and array of DrawingTickets and BaseTickets
 */
const groupByStartDate = (
    data: Array<DrawingTicket | BaseTicket>,
    ticketType: TicketTypeResponse,
): Map<string, Array<DrawingTicket | BaseTicket>> => {
    const response: Map<string, Array<DrawingTicket | BaseTicket>> = new Map();
    data.forEach((ticket: DrawingTicket | BaseTicket) => {
        let dateKey;
        if (isDrawingTicket(ticket)) {
            ticket.outcomes.forEach((drawingOutcome: Outcome) => {
                if (drawingOutcome.details.type === ticketType) {
                    dateKey = moment(drawingOutcome.details.start_date)
                        .tz(drawingOutcome.details.meta_data.location.time_zone)
                        .format('YYYY-MM-DD');
                    if (!response.get(dateKey)) {
                        response.set(dateKey, []);
                    }

                    response.get(dateKey)?.push(ticket);
                }
            });
        } else if (ticket.details.type === ticketType) {
            dateKey = moment(ticket.details.start_date)
                .tz(ticket.details.meta_data.location.time_zone)
                .format('YYYY-MM-DD');
            if (!response.get(dateKey)) {
                response.set(dateKey, []);
            }
            response.get(dateKey)?.push(ticket);
        }
    });

    return response;
};

/**
 * Groups the tickets by their event_id
 * {
 *      "date": [
 *          { DrawingTicket | BaseTicket }
 *      ]
 * }
 *
 * to this:
 * {
 *     "date": {
 *         "event_id": [
 *             {DrawingTicket | BaseTicket}
 *         ]
 *     }
 * }
 * @param data an array of DrawingTickets and BaseTickets to be grouped
 * @param ticketType MyTicketTypeResponse value
 * @return Map where the key is the start date and value is and array of DrawingTickets and BaseTickets
 */
const groupByEventId = (
    data: Map<string, Array<DrawingTicket | BaseTicket>>,
    ticketType: TicketTypeResponse,
): Map<string, Map<string, Array<DrawingTicket | BaseTicket>>> => {
    const response: Map<string, Map<string, Array<DrawingTicket | BaseTicket>>> = new Map();
    data.forEach((tickets: Array<DrawingTicket | BaseTicket>, dateKey) => {
        const ticketData: Map<string, Array<DrawingTicket | BaseTicket>> = new Map();
        tickets.forEach((ticket: DrawingTicket | BaseTicket) => {
            if (isDrawingTicket(ticket)) {
                ticket.outcomes.forEach((drawingOutcome: Outcome) => {
                    const eventId: string = drawingOutcome.details.meta_data.event_id.toString();
                    if (drawingOutcome.details.type === ticketType) {
                        if (!ticketData.get(eventId)) {
                            ticketData.set(eventId, []);
                        }
                        ticketData.get(eventId)?.push(ticket);
                    }
                });
            } else if (ticket.details.type === ticketType) {
                const eventId: string = ticket.details.meta_data.event_id.toString();

                if (!ticketData.get(eventId)) {
                    ticketData.set(eventId, []);
                }
                ticketData.get(eventId)?.push(ticket);
            }
        });
        response.set(dateKey, ticketData);
    });

    return response;
};

/**
 * Looks at the ticket type and returns true if this is a Warp Pipe Pass.
 * @param ticketType MyTicketTypeResponse
 * @return boolean: true if this is a WPP, false otherwise
 */
export const isWarpPipePass = (
    ticketType: TicketTypeResponse | TicketResource | string,
): boolean => {
    return (
        ticketType === TicketResource.TIME_SLOTS ||
        ticketType === TicketTypeResponse.TIME_SLOT_TICKET
    );
};

/**
 * Looks at the ticket type and returns true if this is a Ticket Drawing.
 * @param ticketType MyTicketTypeResponse
 * @return boolean: true if this is a WPP Drawing, false otherwise
 */
export const isTicketDrawing = (
    ticketType: TicketTypeResponse | TicketResource | string,
): boolean => {
    return (
        ticketType === TicketResource.DRAWINGS || ticketType === TicketTypeResponse.DRAWING_TICKET
    );
};

/**
 * Looks at the ticket type and returns true if this is a Ticket.
 * @param ticketType MyTicketTypeResponse
 * @return boolean: true if this is a Ticket, false otherwise
 */
export const isEventGateTicket = (ticketType: TicketTypeResponse | TicketResource | string) => {
    return (
        ticketType === TicketResource.EVENT_GATES ||
        ticketType === TicketTypeResponse.EVENT_GATE_TICKET
    );
};

/**
 * Looks at the ticket type and returns true if this is a Time-slot.
 * @param ticketType MyTicketTypeResponse
 * @return boolean: true if this is a Time-slot, false otherwise
 */
export const isTimeSlotTicket = (ticketType: TicketTypeResponse | TicketResource | string) => {
    return (
        ticketType === TicketResource.TIME_SLOTS ||
        ticketType === TicketTypeResponse.TIME_SLOT_TICKET
    );
};

// Default configuration for the registration toast
const registrationToastConfig = {
    position: toast.POSITION.TOP_RIGHT,
    hideProgressBar: true,
    delay: 1000,
};

/**
 * Helper function to show a Toast when a user successfully registers or updates a WPP or a Ticket
 * The only time we will show the toast is if the routeState has toastData.
 *
 * @param routeState a MyTicketsRerouteState object with new state details to show in the Toast
 */
export const showRegistrationToast = (routeState: MyTicketsRerouteState | null) => {
    if (!routeState || !routeState.toastData) {
        return;
    }

    const localizedStrings = useContext(LocalizationContext);
    const { toastData } = routeState;

    if (toastData.isUpdate) {
        toast.success(
            <ToastContent
                content={localizedStrings.formatString(
                    localizedStrings.account.rsvps.reservationUpdated,
                    <b>{toastData.ticketName}</b>,
                )}
            />,
            registrationToastConfig,
        );
    } else if (toastData.isDelete) {
        toast.success(
            <ToastContent
                content={localizedStrings.formatString(
                    localizedStrings.account.rsvps.reservationRemoved,
                    <b>{toastData.ticketName}</b>,
                )}
            />,
            registrationToastConfig,
        );
    } else if (toastData.isDrawing) {
        toast.success(
            <ToastContent
                content={localizedStrings.formatString(
                    localizedStrings.account.rsvps.drawingTicketAdded,
                    { ticketName: <b>{toastData.ticketName}</b> },
                )}
            />,
            registrationToastConfig,
        );
    } else if (toastData.isWPP) {
        toast.success(
            <ToastContent
                content={localizedStrings.formatString(
                    localizedStrings.account.rsvps.warpPipePassAdded,
                    {
                        activityName: <b>{toastData.ticketName}</b>,
                    },
                )}
            />,
            registrationToastConfig,
        );
    } else {
        toast.success(
            <ToastContent
                content={localizedStrings.formatString(localizedStrings.account.rsvps.ticketAdded, {
                    ticketName: <b>{toastData.ticketName}</b>,
                })}
            />,
            registrationToastConfig,
        );
    }
};

/**
 * Given a Party, find the host
 * @param party: Party to search
 */
export const findHostOfTheParty = (party: Party): PartyMember | undefined => {
    return party.members.find((member: PartyMember) => {
        return member.role === MemberRoleType.HOST;
    });
};

/**
 * Check to see if the user is the host_participant_id. Otherwise find this participant in the Party.
 * If found, check to see if they are the host or not.
 * @param participantId participant ID we are searching for
 * @param party Party to search
 */
export const isHostOfTheParty = (participantId: number, party: Party): boolean => {
    if (party.host_participant_id === participantId) {
        return true;
    }

    const host = party.members.find((member: PartyMember) => {
        return participantId === member.participant_id;
    });
    return host !== undefined && host.role === MemberRoleType.HOST;
};

/**
 * Find participant in the Party and use birthday to check if teen or adult
 * @param participantId participant ID we are searching for
 * @param party Party to search
 * @return boolean
 */
export const isPartyMemberTeenOrAdult = (participantId: number, party: Party): boolean => {
    const partyMember = party.members.find((member: PartyMember) => {
        return participantId === member.participant_id;
    });
    if (partyMember !== undefined && partyMember.birthday !== null) {
        return isTeenOrAdult(partyMember.birthday);
    }
    return false;
};

/**
 * Generic method to see if a given birthday constitute an user that is either a teen or an adult.
 * @param birthday - Birthday string value in yyyy-mm-dd format
 * @return Returns true if teen or adult
 */
export const isTeenOrAdult = (birthday: string | null): boolean => {
    if (!birthday) {
        return false;
    }

    const age = moment().diff(moment(birthday, 'YYYY-MM-DD'), 'year');
    return age >= TEEN;
};

/**
 * Given a party, find the provided participant by ID
 * @param participantId participant ID we are searching for
 * @param party Party to search
 * @returns Returns the PartyMember object if they were found, otherwise null
 */
export const findMemberInParty = (participantId: number, party: Party): PartyMember | null =>
    party.members.find((m) => m.participant_id === participantId) || null;

/**
 * Format the dates based on if start and end dates are on different days or the same day
 * @param startDate start date
 * @param endDate end date
 * @param timeZone time zone
 * @param localizedStrings localized strings
 */
export const createTicketDateDisplay = (
    startDate: string,
    endDate: string,
    timeZone: string,
    localizedStrings: TickTockLocalizedStrings,
) => {
    const isSameDay = checkIfSameDay(startDate, endDate, timeZone);
    return (
        <span>
            <div className="date">
                <LocalizedDate
                    date={startDate}
                    format={localizedStrings.dates.eventTickets.dateRange}
                    timezone={timeZone}
                />
                {!isSameDay ? (
                    <>
                        {' - '}
                        <LocalizedDate
                            date={endDate}
                            format={localizedStrings.dates.eventTickets.dateRange}
                            timezone={timeZone}
                        />
                    </>
                ) : null}
            </div>
            {isSameDay ? (
                <div className="time">
                    <LocalizedDate
                        date={startDate}
                        format={localizedStrings.dates.eventTickets.same_day_time}
                        timezone={timeZone}
                    />
                    {' - '}
                    <LocalizedDate
                        date={endDate}
                        format={localizedStrings.dates.eventTickets.same_day_time}
                        timezone={timeZone}
                    />{' '}
                    <LocalizedDate
                        date={endDate}
                        format={localizedStrings.dates.eventTickets.timeZone}
                        timezone={timeZone}
                    />
                </div>
            ) : null}
        </span>
    );
};

/**
 * Find the drawing ticket for this eventId in myTickets
 * @param eventId
 * @param tickets - response from myTickets end point
 */
export const findDrawingTicketForEvent = (
    eventId: number,
    tickets: Map<string, Array<DrawingTicket | BaseTicket>>,
): DrawingTicket | null => {
    const allTickets: Array<DrawingTicket | BaseTicket> = Array.from(tickets.values()).flat();

    // Find the proper ticket for this event
    const foundDrawingTicket = allTickets.find((ticket: DrawingTicket | BaseTicket) => {
        if (isDrawingTicket(ticket)) {
            const foundOutcome = ticket.outcomes.find(
                (outcome: Outcome) =>
                    outcome.details.meta_data.event_id === eventId &&
                    outcome.details.type === TicketTypeResponse.EVENT_GATE_TICKET,
            );
            if (foundOutcome) {
                return ticket;
            }
        }
        return null;
    });

    if (foundDrawingTicket && isDrawingTicket(foundDrawingTicket)) {
        return foundDrawingTicket;
    }
    return null;
};

/**
 * Find the base ticket for this eventId in myTickets
 * @param eventId
 * @param tickets - response from myTickets end point
 */
export const findBaseTicketForEvent = (
    eventId: number,
    tickets: Map<string, Array<DrawingTicket | BaseTicket>>,
): BaseTicket | null => {
    const allTickets: Array<DrawingTicket | BaseTicket> = Array.from(tickets.values()).flat();

    const foundBaseTicket = allTickets.find((ticket: DrawingTicket | BaseTicket) => {
        if (!isDrawingTicket(ticket)) {
            return ticket.details.meta_data.event_id === eventId &&
                ticket.details.type === TicketTypeResponse.EVENT_GATE_TICKET
                ? ticket
                : null;
        }
        return null;
    });

    if (foundBaseTicket && !isDrawingTicket(foundBaseTicket)) {
        return foundBaseTicket;
    }
    return null;
};

/**
 * If this userId is the host of this party, then sort the party so that the host is first.
 * If this userId is not the host, filter out everyone but this userId's ticket
 * @param party Party to search
 * @param userParticipantId Currently logged in user's participant ID
 */
export const sortAndFilterParty = (party: Party, userParticipantId: number): Array<PartyMember> => {
    let sortedPartyMembers: Array<PartyMember> = [];
    const hostOfTheParty = findHostOfTheParty(party);
    if (
        (hostOfTheParty && hostOfTheParty.participant_id === userParticipantId) ||
        userParticipantId === party.host_participant_id
    ) {
        // Sort party members so host is first
        sortedPartyMembers = party.members.sort((x: PartyMember, y: PartyMember) => {
            if (party.host_participant_id === y.participant_id) {
                return 1;
            }
            if (isHostOfTheParty(x.participant_id, party)) {
                return -1;
            }
            if (isHostOfTheParty(y.participant_id, party)) {
                return 1;
            }
            return 0;
        });
    } else {
        // This user is not the host so filter everyone out except yourself
        const userMember = party.members.find((partyMember: PartyMember) => {
            return userParticipantId === partyMember.participant_id;
        });

        if (userMember) {
            sortedPartyMembers.push(userMember);
        }
    }
    return sortedPartyMembers;
};

// These buttons are used on the Event Details page, specifically for the TicketSection

export type ButtonData = {
    tooltip: Array<string> | string | null;
    label: string;
    disabled: boolean;
    variant: string;
    iconClass: string;
    iconId: string;
    dataTestId: string;
    onClickMethod: () => void;
};

/**
 * Helper method for creating a default ButtonData object
 * @param onClick method to call when this button is clicked
 */
export const createDefaultButton = (onClick: () => void): ButtonData => {
    return {
        tooltip: null,
        label: '',
        disabled: false,
        variant: 'primary',
        iconClass: 'fas fa-chevron-right',
        iconId: '',
        dataTestId: '',
        onClickMethod: onClick,
    };
};

/**
 * Creates an enabled button
 * @param onClick what to call when user clicks on this button
 * @param label the button label
 * @param tooltip optional tooltip
 * @param testId optional data-testid value
 * @param buttonClass CSS class for the button
 */
export const createEnabledButton = (
    onClick: () => void,
    label: string,
    tooltip: Array<string> | string | null,
    testId: string,
    variant: string = 'primary',
): ButtonData => {
    const button = createDefaultButton(onClick);
    button.disabled = false;
    button.label = label;
    button.tooltip = tooltip;
    button.variant = variant;
    button.iconId = 'ticket-chevron';
    button.iconClass = 'fas fa-chevron-right';
    button.dataTestId = `${testId}-enabled`;

    return button;
};

/**
 * Creates a disabled button. An empty method is used for onClick.
 * @param label the button label
 * @param tooltip optional tooltip
 * @param testId optional data-testid value
 * @param buttonClass CSS class for the button
 */
export const createDisabledButton = (
    label: string,
    tooltip: Array<string> | string | null,
    testId: string,
    variant: string,
): ButtonData => {
    const button = createDefaultButton(() => {});
    button.disabled = true;
    button.label = label;
    button.tooltip = tooltip;
    button.variant = variant;
    button.iconId = 'ticket-circle';
    button.iconClass = 'far fa-info-circle';
    button.dataTestId = `${testId}-disabled`;

    return button;
};

/**
 * Create a button by sending in all params
 * @param label the button label
 * @param tooltip optional tooltip
 * @param variant Variant of the Bootstrap button
 * @param disabled boolean to disable/enable the button
 * @param iconId id for this icon
 * @param iconClass CSS class for this icon
 * @param testId optional data-testid value
 */
export const createButton = (
    disabled: boolean,
    label: string,
    tooltip: Array<string> | string | null,
    variant: string,
    iconId: string,
    iconClass: string,
    testId: string,
): ButtonData => {
    const button = createDefaultButton(() => {});
    button.disabled = disabled;
    button.label = label;
    button.tooltip = tooltip;
    button.variant = variant;
    button.iconId = iconId;
    button.iconClass = iconClass;
    button.dataTestId = `${testId}`;

    return button;
};

/**
 * Given a Party, check to see if the provided participant has not completed their consents (if required)
 * @param party {Party} - The party that contains the participant to check for
 * @param participantId - The participant ID to check for consent status
 */
export const consentRequiredForPartyMember = (party: Party, participantId: string): boolean => {
    const partyMember = party.members.find(
        (member) => `${member.participant_id}` === participantId,
    );
    return partyMember && partyMember.consents.completed !== undefined
        ? !partyMember.consents.completed
        : false;
};
