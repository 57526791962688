import { PublicLocalizationObject } from '../../resources/locales/en';
import { isAxiosError } from '../api/ApiUtils';
import { ErrorContextProps } from '../context/ErrorModalContext';
import { ErrorModalMessage, GrandPrixServiceError } from './types';

/**
 * Transforms a GrandPrixServiceError into a message that can be used by the ErrorModal
 * @param error {GrandPrixServiceError} - The service error that was thrown
 * @param localizedStrings {PublicLocalizationObject} - The object of localized strings
 * @returns Returns an ErrorModalMessage that explains the GrandPrixServiceError
 */
export const getModalErrorMessage = (
    error: GrandPrixServiceError,
    localizedStrings: PublicLocalizationObject,
): ErrorModalMessage => {
    const serviceErrors = localizedStrings.error.serviceErrorModal as {
        [gprixErrorCode: string]: ErrorModalMessage | undefined;
    };
    const message = serviceErrors[error.code];

    return message || localizedStrings.error.serviceErrorModal.unknown;
};

/**
 * Given an error, update the error modal message appropriately. If the error is determined to be an axios error, then
 * try and retrieve the error message from the localized objects.
 * @param error {Error} - The JavaScript error that was thrown
 * @param localizedStrings {PublicLocalizationObject} - The localization object
 * @param errorContext {ErrorContextProps} - The error context that contains reference to update the modal message
 */
export const updateErrorModalMessageFromError = (
    error: Error,
    localizedStrings: PublicLocalizationObject,
    errorContext: ErrorContextProps,
) => {
    if (isAxiosError(error) && error.response && error.response.data) {
        const grandPrixError = error.response.data as GrandPrixServiceError;
        errorContext.updateMessage(getModalErrorMessage(grandPrixError, localizedStrings));
    } else {
        errorContext.updateMessage(localizedStrings.error.serviceErrorModal.unknown);
    }
};

/**
 * A utility function to determine if the response from the API contains a valid Grand Prix
 * error message. If it does, it will create a GrandPrixServiceError object and returns it to be
 * handled by the component. Otherwise, throws an error
 * @param e - The exception caught by the catch block. It is typed as 'any' because that's how it is
 * typed by TypeScript (Not awesome).
 */
export const checkExceptionForGrandPrixError = <T>(e: any): GrandPrixServiceError<T> => {
    if (e.response && e.response.data && e.response.data.code) {
        return e.response.data;
    }
    throw e;
};

/**
 * Given a fetch "response.data" object, check if we have a GP error code or not
 * @param responseData the fetch response data, "response.data"
 */
export const hasGrandPrixError = (responseData: any): boolean => {
    return !!(responseData && responseData.code && responseData.code.includes('GP'));
};
