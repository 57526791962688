import React from 'react';
import LocalizedStrings, { LocalizedStrings as LocalizedStringsType } from 'react-localization';
import en, { PublicLocalizationObject } from '../../resources/locales/en';
import au from '../../resources/locales/au';
import { InternalLocalizationObject } from '../../resources/locales/en.internal';

export type InternalLocalizedStrings = LocalizedStringsType<
    PublicLocalizationObject & InternalLocalizationObject
>;

export const localizedStrings = new LocalizedStrings<
    PublicLocalizationObject | (PublicLocalizationObject & InternalLocalizationObject)
>({ 'en-US': en, 'en-AU': au });
const LocalizationContext = React.createContext(localizedStrings);

export type TickTockLocalizedStrings = typeof localizedStrings;
export default LocalizationContext;
