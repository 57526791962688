import { useContext } from 'react';
import 'moment/locale/ja';
import moment from 'moment-timezone/builds/moment-timezone-with-data-1970-2030.min';

import { SupportedLocale } from '../../utils/types';
import LanguageContext from '../../context/LanguageContext';
import { getSupportedLocale } from '../../utils/LanguageUtils';
import { isInternal } from '../../utils/InternalUtils';
import LocalizationContext from '../../context/LocalizationContext';

type Props = {
    date: string;
    format: string;
    timezone: string;
    upperCase?: boolean;
};

const LocalizedDate = ({ date, format, timezone, upperCase }: Props) => {
    const { locale } = useContext(LanguageContext);
    const localizedStrings = useContext(LocalizationContext);

    let supportedLocale: SupportedLocale = getSupportedLocale(locale);
    if (isInternal(localizedStrings)) {
        // If internal, fix it to English so that we don't have moment
        // render dates in other locales.
        supportedLocale = SupportedLocale.EN_US;
    }

    const formattedDate = moment(date).locale(supportedLocale).tz(timezone).format(format);

    if (upperCase) {
        return formattedDate.toUpperCase();
    }
    return formattedDate;
};

export default LocalizedDate;
