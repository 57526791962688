import { ErrorMessage } from '@hookform/error-message';
import get from 'lodash.get';
import React, { useContext } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useFormContext } from 'react-hook-form';
import { FormControlProps } from 'react-bootstrap/FormControl';
import LocalizationContext from '../../../context/LocalizationContext';
import InformationalToolTip from '../../policies/InformationalToolTip';

interface GenericFieldProps extends FormControlProps {
    label: string;
    nickname: string;
    formControlReference: string;
    isRequired: boolean;
    maxLength?: number;
    validator?: (value: string) => boolean;
    controlType?: string;
    defaultValue?: string;
    tooltip?: string;
}

/**
 * Generic Form.Control
 *
 * @param label the Form.Label
 * @param nickname - Nintendo Account nickname, used for aria-label to help identify who the field is for
 * @param formControlReference - unique id for this Form.Group and Form.Control
 * @param isRequired - is this field required or not
 * @param maxLength - optional maxLength for this input field
 * @param onChange - optional onChange callback
 * @param disabled - optional is this field disabled or not (defaults to false). Note that a disabled field will NOT
 *  submit its data when the form is submitted
 * @param readOnly - optional is this field readonly or not (defaults to false). Note that a readonly field will
 *  submit its data when the form is submitted
 * @param validator - optional method used to validate this Form.Control data
 * @param controlType - optional The HTML input type, which is only relevant if 'as' attribute is 'input' (the default).
 * @param placeholder - optional Set placeholder text for this Form.Control. If both placeholder and defaultValue are
 *  set, the defaultValue will be used.
 * @param defaultValue - optional Set the default value for this Form.Control
 * @param tooltip - optional tooltip
 */
const GenericField = ({
    label,
    nickname,
    formControlReference,
    isRequired,
    maxLength,
    onChange,
    disabled = false,
    readOnly = false,
    validator,
    controlType,
    placeholder,
    defaultValue,
    tooltip,
}: GenericFieldProps) => {
    const localizedStrings = useContext(LocalizationContext);
    const { register, formState } = useFormContext();

    const fieldOptions = validator
        ? {
              required: isRequired
                  ? (localizedStrings.formatString(localizedStrings.error.formError.required, {
                        attribute: label,
                    }) as string)
                  : false,
              validate: (value: string) =>
                  validator(value) ||
                  (localizedStrings.formatString(localizedStrings.error.formError.default, {
                      attribute: label,
                  }) as string),
              onChange: onChange || undefined,
          }
        : {
              required: isRequired
                  ? (localizedStrings.formatString(localizedStrings.error.formError.required, {
                        attribute: label,
                    }) as string)
                  : false,
              onChange: onChange || undefined,
          };

    let errorClassName: string | null = null;
    if (get(formState.errors, formControlReference)) {
        errorClassName = 'border-danger';
    }

    return (
        <Form.Group as={Col} className="generic-field-form-group">
            <Form.Label
                htmlFor={formControlReference}
                className="generic-field-form-label"
                aria-label={`${nickname} ${label}`}
            >
                {label} {isRequired ? <span className="field-error-color">*</span> : null}
                {tooltip ? <InformationalToolTip tooltip={tooltip} /> : null}
            </Form.Label>

            <Form.Control
                maxLength={maxLength || undefined}
                id={formControlReference}
                data-testid={formControlReference}
                className={`generic-field-form-control  ${
                    readOnly && 'generic-field-form-read-only'
                } ${(errorClassName || false) && 'border-danger'}`}
                type={controlType}
                defaultValue={defaultValue}
                placeholder={placeholder}
                disabled={disabled}
                readOnly={readOnly}
                {...register(formControlReference, fieldOptions)}
            />

            <ErrorMessage
                errors={formState.errors}
                name={formControlReference}
                render={({ message }) => <div className="generic-field-form-error">{message}</div>}
            />
        </Form.Group>
    );
};

export default GenericField;
