import Moment from 'moment/moment';
import React, { useContext } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import LanguageContext from '../../../context/LanguageContext';
import LocalizationContext from '../../../context/LocalizationContext';
import { getSupportedLocale } from '../../../utils/LanguageUtils';
import './FieldStyles.css';

type StaticBirthdayFieldProps = {
    userId: string;
    birthday: string;
};

/**
 * Component for rendering a birthday readonly field
 *
 * @param userId userId of the user logged in
 * @param birthday string birthday value parseable by Moment library
 */
const ReadOnlyBirthdayField = ({ userId, birthday }: StaticBirthdayFieldProps) => {
    const localizedStrings = useContext(LocalizationContext);
    const { locale } = useContext(LanguageContext);

    return (
        <Form.Group as={Row}>
            <Col xs={3}>
                <Form.Label className="mt-2">
                    {localizedStrings.tickets.registration.guest.birthday}
                </Form.Label>
            </Col>
            <Col className="name-field-user-name">
                <Form.Control
                    className="name-field-spaced-text"
                    id={`${userId}-birthday`}
                    plaintext
                    readOnly
                    value={Moment(birthday)
                        .locale(getSupportedLocale(locale))
                        .format(localizedStrings.dates.birthday)}
                />
            </Col>
        </Form.Group>
    );
};

export default ReadOnlyBirthdayField;
