import React from 'react';
import './DotDivider.css';

type DotDividerProps = {
    showIcon?: boolean;
    className?: string;
    dividerClassName?: string;
    dividerIconClassName?: string;
};

/**
 * A simple component rendering out a dotted line as a divider between list content.
 * @param showIcon - boolean to show the ticket icon or not
 * @param className - Used for dot divider styling
 * @param dividerClassName - Used for nested divider styling
 * @param dividerIconClassName - Optional Divider icon class names. Defaults to 'divider-icon-rotated fas fa-ticket-alt'
 */
const DotDivider = ({
    className,
    dividerClassName,
    dividerIconClassName = 'divider-icon-rotated fas fa-ticket-alt',
    showIcon = true,
}: DotDividerProps) => (
    <div className={`d-flex justify-content-center align-items-center ${className || 'my-4'}`}>
        <div className={`divider ${dividerClassName}`} />
        {showIcon && <i className={dividerIconClassName} />}
        <div className={`divider ${dividerClassName}`} />
    </div>
);

export default DotDivider;
