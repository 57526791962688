import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import LocalizationContext from '../../context/LocalizationContext';
import './NavBar.css';
import LanguageContext from '../../context/LanguageContext';
import { isInternal } from '../../utils/InternalUtils';

const NavBar = (props: { userElement: JSX.Element }) => {
    const localizedStrings = useContext(LocalizationContext);
    const { updateLocale } = useContext(LanguageContext);
    const internalApp = isInternal(localizedStrings);

    const languagePicker = internalApp ? null : (
        <Nav className="justify-content-end">
            <Nav.Item className="nav-item">
                <Dropdown>
                    <Dropdown.Toggle variant="link" className="nav-link" id="language-dropdown">
                        <i className="fas fa-globe me-2" />
                        {localizedStrings.general.currentLanguage}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item as="button" onClick={() => updateLocale('en-US')}>
                            English
                        </Dropdown.Item>
                        <Dropdown.Item as="button" onClick={() => updateLocale('en-AU')}>
                            English (Australia)
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Nav.Item>
        </Nav>
    );

    return (
        <Navbar className="top-nav" expand="lg" variant="dark">
            <Container fluid>
                <Navbar.Toggle className="hide-print" data-testid="navbar-toggler" />
                <Navbar.Brand as={NavLink} className="navbar-brand-nintendo" to="/events">
                    <img
                        src={require('../../../resources/images/nintendo_logo_120x30_red.jpg')}
                        alt="Nintendo"
                    />
                </Navbar.Brand>

                <div className="nav-back nav-login" />

                <Navbar.Collapse id="navbarSupportedContent">
                    <Nav className="me-auto">
                        <Nav.Item>
                            <NavLink
                                data-testid="events-nav-link"
                                className="nav-link"
                                to="/events"
                            >
                                <i className="nav-link-icon fas fa-calendar" />
                                {localizedStrings.tabs.events}
                            </NavLink>
                        </Nav.Item>
                        <Nav.Item>
                            <NavLink
                                data-testid="my-tickets-passes-nav-link"
                                className="nav-link"
                                to="/my-tickets-passes"
                            >
                                <i className="nav-link-icon nav-link-icon-rotated fas fa-ticket-alt" />
                                {localizedStrings.tabs.myTicketsAndPasses}
                            </NavLink>
                        </Nav.Item>
                    </Nav>
                    {languagePicker}
                    <Nav className="justify-content-end">
                        <Nav.Item id="mii-login">{props.userElement}</Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavBar;
