import React, { useContext } from 'react';
import moment from 'moment';
import Container from 'react-bootstrap/Container';
import LocalizationContext from '../../context/LocalizationContext';
import LanguageContext from '../../context/LanguageContext';
import { Audience, SupportedLocale } from '../../utils/types';
import { isAudience, isInternal } from '../../utils/InternalUtils';
import PrivacyPolicyLink from '../policies/PrivacyPolicyLink';
import './Footer.css';

const Footer = () => {
    const localizedStrings = useContext(LocalizationContext);
    const { locale } = useContext(LanguageContext);

    if (isAudience(Audience.CORPORATE_EVENT)) {
        return null;
    }

    return (
        <footer className="footer border-top d-flex" role="contentinfo" data-testid="app-footer">
            <Container>
                <p className="text-muted disclaimer-text">
                    <span>
                        {localizedStrings.formatString(
                            localizedStrings.footer.disclaimer,
                            moment().format('YYYY'),
                        )}
                    </span>
                    &nbsp;
                    <PrivacyPolicyLink isInternal={isInternal(localizedStrings)} />
                    {locale === SupportedLocale.EN_US || SupportedLocale.EN_AU ? (
                        <>
                            <FooterLinkDivider />
                            <a
                                href={localizedStrings.footer.termsOfUseUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {localizedStrings.footer.termsOfUse}
                            </a>
                        </>
                    ) : null}
                </p>
            </Container>
        </footer>
    );
};

/**
 * Sub-component function for rendering the vertical pipe that divides the Privacy
 * Policy link and the Terms of Use link.
 */
const FooterLinkDivider = () => {
    const localization = useContext(LocalizationContext);
    if (localization.footer.termsOfUse) {
        return (
            <>
                &nbsp;<span className="break">|</span>&nbsp;
            </>
        );
    }
    return null;
};

export default Footer;
