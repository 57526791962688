import React, { useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { ErrorOption, FieldPath, FieldValues, FormProvider, useForm } from 'react-hook-form';
import Loading from 'react-loading';
import LocalizationContext from '../../context/LocalizationContext';

type RegistrationFormProps = {
    title: string | JSX.Element | null;
    onSubmit?: (
        data: FieldValues,
        setError?: (
            name: FieldPath<FieldValues>,
            error: ErrorOption,
            options?: { shouldFocus: boolean },
        ) => void,
    ) => {};

    children?: Array<React.ReactElement>;
    buttonLabel?: string;
};

/**
 * Form component for various registration processes. Since the various registration components (e.g. TicketSignUp)
 * can get rather larger, this component is to help clean those components up and to standardize on around single
 * FormProvider.
 *
 * @param title: title
 * @param onSubmit: form submission callback method with the following params:
 *      data: passes a FieldValues object with all the fields data
 *      setError: optional react-hook-form setError hook, so you can add custom error messages based on responses
 * @param children: optional Array of ReactElements. Usually any of the registration/block components
 * @param buttonLabel: optional string label for the button. Defaults to "Confirm"
 */
const RegistrationForm = ({ title, onSubmit, children, buttonLabel }: RegistrationFormProps) => {
    const localizedStrings = useContext(LocalizationContext);
    const formMethods = useForm({ mode: 'onChange', shouldUnregister: true });

    const { setError } = formMethods;
    const { isSubmitting, isValidating } = formMethods.formState;

    const [isSubmittingToBackend, setIsSubmittingToBackend] = useState(false);

    // Make sure the button is disabled while we are submitting the data...
    const onFormSubmit = async (values: FieldValues) => {
        setIsSubmittingToBackend(true);
        if (onSubmit) {
            await onSubmit(values, setError);
        }
        setIsSubmittingToBackend(false);
    };

    return (
        <div data-testid="reservation-container">
            {React.isValidElement(title) ? title : <h2 className="mt-0">{title}</h2>}
            <FormProvider {...formMethods}>
                <form
                    onSubmit={formMethods.handleSubmit(async (values) => {
                        await onFormSubmit(values);
                    })}
                >
                    <div className="sr-only">
                        {localizedStrings.tickets.registration.registrant.requiredFields}
                    </div>

                    {children}

                    <div className="row my-5 mx-0">
                        <Button
                            variant="primary"
                            className="w-100 d-flex justify-content-center align-items-center"
                            disabled={isSubmitting || isValidating || isSubmittingToBackend}
                            type="submit"
                            data-testid="ticket-signup-submit-button"
                        >
                            {isSubmitting || isValidating || isSubmittingToBackend ? (
                                <Loading type="spin" height={20} width={20} />
                            ) : (
                                buttonLabel || localizedStrings.general.confirm
                            )}
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </div>
    );
};

export default RegistrationForm;
