import React from 'react';
import moment from 'moment';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
    formatLocalizedAddress,
    formatTimeRange,
    formatDateRange,
    convertISO8601ToTimezone,
} from '../../../utils/FormattingUtils';
import LocalizationContext from '../../../context/LocalizationContext';
import { Location } from '../../../utils/types';

type Props = {
    title: string;
    startTime: string;
    endTime: string;
    location: Location;
    drawing?: boolean;
    drawingRules?: string;
    typeIcon?: string;
    typeName?: string | Array<string> | JSX.Element;
    showHours?: boolean;
    showDuration?: boolean;
};

/**
 * Component for displaying registration details for activities, time slots, tickets, etc.
 *
 * @param title - Title of the details section.
 * @param startTime - Start time of the resource
 * @param endTime - End time of the resource
 * @param location - Location where the resource is taking place at.
 * @param drawing - Is drawing or not
 * @param drawingRules - Optional drawing rules
 * @param typeIcon - If there is a type associated with this resource, uses this FontAwesome icon class
 * @param typeName - Name of the resource type
 * @param showHours - Boolean field to show the hours for the resource start/end time
 * @param showDuration - Boolean field to show the duration of a given model
 */
const DetailsBlock = ({
    title,
    startTime,
    endTime,
    location,
    drawing,
    drawingRules,
    typeIcon,
    typeName,
    showHours,
    showDuration,
}: Props) => {
    const localizedStrings = React.useContext(LocalizationContext);

    // Format dates
    const timeRange = formatTimeRange(startTime, endTime, location.time_zone);
    const timezone = convertISO8601ToTimezone(startTime, location.time_zone, 'z');
    const dateRange = formatDateRange(
        startTime,
        endTime,
        location.time_zone,
        localizedStrings,
        localizedStrings.dates.eventTickets.dateRange,
    );

    return (
        <>
            <Row>
                <Col md={12}>
                    <h4 className="mt-4 section-header">
                        <i className="me-2 nav-link-icon nav-link-icon-rotated fas fa-ticket-alt" />
                        {title}
                    </h4>
                    <Container>
                        <table className="mt-1 tbl_event_details">
                            <tbody>
                                {/* Detail type / information */}
                                {typeIcon && typeName ? (
                                    <tr>
                                        <td
                                            aria-label={
                                                localizedStrings.activities.details.iconLabel
                                                    .activityType
                                            }
                                            valign="top"
                                        >
                                            <i className={typeIcon || 'fas fa-question'} />
                                        </td>
                                        <td>{typeName}</td>
                                    </tr>
                                ) : null}
                                {/* Time (start/end + additional hours info if enabled) */}
                                <tr>
                                    <td
                                        aria-label={
                                            localizedStrings.activities.details.iconLabel
                                                .activityTime
                                        }
                                    >
                                        <i className="far fa-calendar-alt" />
                                    </td>
                                    <td>
                                        <p className="mb-0">{dateRange}</p>
                                    </td>
                                </tr>
                                {showHours ? (
                                    <tr>
                                        <td
                                            aria-label={
                                                localizedStrings.activities.details.iconLabel
                                                    .activityTime
                                            }
                                        >
                                            <i className="far fa-clock" />
                                        </td>
                                        <td>
                                            <p className="mb-0">
                                                <span>
                                                    {timeRange} {timezone}
                                                </span>{' '}
                                                <span>
                                                    {showDuration
                                                        ? `(${moment
                                                              .duration(
                                                                  moment(endTime).diff(startTime),
                                                              )
                                                              .asMinutes()}${
                                                              localizedStrings.reservations.minutes
                                                          })`
                                                        : null}
                                                </span>
                                            </p>
                                        </td>
                                    </tr>
                                ) : null}
                                {/* Location info */}
                                <tr>
                                    <td
                                        aria-label={
                                            localizedStrings.activities.details.iconLabel.location
                                        }
                                        valign="top"
                                    >
                                        <i className="far fa-map-marker-alt" />
                                    </td>
                                    <td>
                                        <p className="mb-0">{location.name}</p>
                                        <p className="text-muted mt-0 mb-0">
                                            <small data-testid="full-address">
                                                {formatLocalizedAddress(location, localizedStrings)}
                                            </small>
                                        </p>
                                    </td>
                                </tr>
                                {/* Drawing vs First Come First Served */}
                                <tr>
                                    <td
                                        aria-label={
                                            localizedStrings.activities.details.iconLabel
                                                .registration
                                        }
                                        valign="top"
                                    >
                                        <i className="fas fa-ticket-alt me-1" />
                                    </td>
                                    <td>
                                        <p className="mb-0">
                                            {drawing
                                                ? localizedStrings.drawing.drawing
                                                : localizedStrings.drawing.firstComeFirstServed}
                                        </p>
                                        {drawing ? (
                                            <p className="text-muted mt-0 mb-0">
                                                <small>
                                                    {drawingRules
                                                        ? localizedStrings.formatString(
                                                              localizedStrings.drawing.ruleX,
                                                              {
                                                                  rule: drawingRules,
                                                              },
                                                          )
                                                        : ''}
                                                </small>
                                            </p>
                                        ) : null}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Container>
                </Col>
            </Row>
        </>
    );
};

export default DetailsBlock;
