import parse from 'html-react-parser';
import Moment from 'moment/moment';
import React, { useContext } from 'react';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { useFormContext } from 'react-hook-form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import LanguageContext from '../../../context/LanguageContext';
import LocalizationContext from '../../../context/LocalizationContext';
import { getAttendeeType } from '../../../utils/AccountUtils';
import { sanitizeForDisplay } from '../../../utils/FormattingUtils';
import { isInternal } from '../../../utils/InternalUtils';
import { getSupportedLocale } from '../../../utils/LanguageUtils';
import {
    DataCollectionAssociation,
    NintendoAccountFamilyUser,
    ParticipantInfoMap,
} from '../../../utils/types';
import DotDivider from '../../common/DotDivider';
import GuestModal from '../../tickets/signup/guests/modal/GuestModal';
import DataCollectionFields from '../fields/DataCollectionFields';
import ReadOnlyBirthdayField from '../fields/ReadOnlyBirthdayField';
import ReadOnlyNameField from '../fields/ReadOnlyNameField';
import { BlockType, generateFormControlReferencePrefix } from '../../../utils/BlockUtils';
import BlockHeader from './BlockHeader';
import './BlockStyles.css';

export type GuestBlockProps = {
    maxGuests: number;
    minGuests: number;
    isDrawing: boolean;
    isActivity: boolean;
    dataCollection: Array<DataCollectionAssociation> | null;
    guestRules?: string;
    guests: Array<NintendoAccountFamilyUser>;
    setGuests: (newGuests: Array<NintendoAccountFamilyUser>) => void;
    ineligibleGuests: Array<string>;
    eligibilityCheck?: {
        eventGateId?: number;
        drawingId?: string;
        timeslotId?: number;
    };
    participantInfoMap?: ParticipantInfoMap;
    tooltip?: JSX.Element;
};

/**
 * Guest Sign-up section in Event Ticket sign-up
 *
 * @param maxGuests - Max number of guests allowed
 * @param minGuests - Min number of guests allowed
 * @param dataCollection - Data collection items provided by GPS.
 * @param guestRules - Text containing rules about guest sign-ups.
 * @param guests - List of currently selected guests
 * @param setGuests - React.useState() setter function
 * @param tooltip - Optional JSX component that contains a tooltip
 * @param eligibilityCheck - A map of eligibility status for the guests
 * @param participantInfoMap - Optional ParticipantInfoMap from the family API
 * @param ineligibleGuests - List of guest Nintendo Account IDs deemed as ineligible
 * @param isDrawing - Determines if this current registration page is for a regular gate
 * @param isActivity - Determines if the current registration is for an activity or an event gate
 */
const GuestBlock = ({
    maxGuests,
    minGuests,
    dataCollection,
    guestRules,
    guests,
    setGuests,
    ineligibleGuests,
    tooltip,
    eligibilityCheck,
    participantInfoMap,
    isDrawing,
    isActivity,
}: GuestBlockProps) => {
    const localizedStrings = useContext(LocalizationContext);
    const { locale } = useContext(LanguageContext);
    const { register, formState, trigger } = useFormContext();
    // Guests removed using the RemoveGuest button are sent back to the Guest modal to update the checkboxes.

    if (isInternal(localizedStrings) || maxGuests <= 0) {
        return null;
    }

    const onGuestModalSubmit = async (addedUsers: Array<NintendoAccountFamilyUser>) => {
        setGuests(addedUsers);
        // Kick off another react-hook-form validation since this might have triggered adding new checkboxes
        // to the ConsentBlock. Only trigger if the form has already been submitted to avoid confusion.
        if (formState.isSubmitted) {
            await trigger();
        }
    };
    const removeGuest = (removedGuest: string) => {
        // setGuests call removes the user's GuestBlock
        setGuests(
            guests.filter((user) => {
                return user.user_id !== removedGuest;
            }),
        );
    };

    // Used for minimum guest check
    const minNumGuestsControlReferenceId = `${BlockType.GUESTS_BLOCK}.guests`;
    // Add data collection form fields for each guest
    const formControlReferencePrefixList: Array<string> = [minNumGuestsControlReferenceId];
    const formFields = guests.map((guest, index) => {
        const formControlReferencePrefix: string = generateFormControlReferencePrefix(
            guest.user_id,
            BlockType.GUESTS_BLOCK,
        );
        const removeGuestButtonLabel = localizedStrings.formatString(
            localizedStrings.tickets.registration.guest.guestRemoveButtonLabel,
            { user: guest.nickname },
        );
        formControlReferencePrefixList.push(formControlReferencePrefix);
        return (
            <div key={guest.user_id}>
                <DotDivider showIcon={false} />
                <div>
                    <span className="guest-block-guest-num">
                        {' '}
                        {localizedStrings.tickets.registration.guest.guest} {index + 1}
                    </span>
                </div>
                <Container>
                    <Row>
                        <Col xs={11}>
                            <Row>
                                <ReadOnlyNameField
                                    userId={guest.user_id}
                                    name={guest.nickname}
                                    attendeeType={getAttendeeType(guest.birthday)}
                                />
                                <ReadOnlyBirthdayField
                                    userId={guest.user_id}
                                    birthday={Moment(guest.birthday)
                                        .locale(getSupportedLocale(locale))
                                        .format(localizedStrings.dates.birthday)}
                                />
                                <DataCollectionFields
                                    userId={guest.user_id}
                                    nickname={guest.nickname}
                                    birthday={guest.birthday}
                                    participantInfo={
                                        participantInfoMap
                                            ? participantInfoMap[guest.user_id]
                                            : undefined
                                    }
                                    formControlReferencePrefix={formControlReferencePrefix}
                                    dataCollection={dataCollection}
                                />
                            </Row>
                        </Col>
                        <Col xs={1} className="guest-block-remove-button-holder">
                            {/* Remove guest button */}
                            <Button
                                aria-label={removeGuestButtonLabel.toString()}
                                className="guest-block-remove-guest-button"
                                onClick={() => removeGuest(guest.user_id)}
                            >
                                <i className="far fa-times" />
                            </Button>
                        </Col>
                    </Row>
                </Container>

                {index === guests.length - 1 ? <DotDivider showIcon={false} /> : null}
            </div>
        );
    });

    return (
        <>
            {maxGuests > 0 || !isActivity ? (
                <>
                    <BlockHeader
                        icon={<i className="me-2 fas fa-user-friends" />}
                        title={localizedStrings.formatString(
                            localizedStrings.tickets.registration.guest.guests,
                            { maxGuests },
                        )}
                        formState={formState}
                        formControlReferencePrefixes={formControlReferencePrefixList}
                        extraErrorMessages={
                            guests.length < minGuests
                                ? [
                                      localizedStrings.formatString(
                                          localizedStrings.error.minNumberOfGuestsError,
                                          {
                                              atLeastNum: (
                                                  <strong>
                                                      {localizedStrings.formatString(
                                                          localizedStrings.error.atLeast,
                                                          {
                                                              expectedNumberOfGuests: minGuests,
                                                          },
                                                      )}
                                                  </strong>
                                              ),
                                          },
                                      ) as string,
                                  ]
                                : []
                        }
                        tooltip={tooltip}
                    />

                    {guestRules && (
                        <Alert variant="warning" className="guest-rules">
                            <p>{parse(sanitizeForDisplay(guestRules))}</p>
                        </Alert>
                    )}
                </>
            ) : null}

            <Container>
                <p>
                    {localizedStrings.formatString(
                        localizedStrings.tickets.registration.guest.guestFamilyHelp,
                        {
                            guestFamilyHelpLink: (
                                <a
                                    href={
                                        localizedStrings.tickets.registration.guest
                                            .guestFamilyHelpUrl
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {
                                        localizedStrings.tickets.registration.guest
                                            .guestFamilyHelpLink
                                    }
                                </a>
                            ),
                        },
                    )}
                </p>
                <GuestModal
                    onConfirm={onGuestModalSubmit}
                    maxGuests={maxGuests}
                    eligibilityCheck={eligibilityCheck}
                    ineligibleGuests={ineligibleGuests}
                    isDrawing={isDrawing}
                    selectedGuests={guests}
                />
                {/* Hidden form controller to handle minimum number of guests check */}
                <Form.Control
                    id={minNumGuestsControlReferenceId}
                    type="hidden"
                    defaultValue={guests.length}
                    {...register(minNumGuestsControlReferenceId, {
                        required: true,
                        validate: () => guests.length >= minGuests,
                    })}
                />
                {formFields}
            </Container>
        </>
    );
};

export default GuestBlock;
