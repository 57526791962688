import React, { useContext } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useFormContext } from 'react-hook-form';
import LocalizationContext from '../../../context/LocalizationContext';
import { getAttendeeType } from '../../../utils/AccountUtils';
import { BlockType, generateFormControlReferencePrefix } from '../../../utils/BlockUtils';
import { getFilesThatRequireAcknowledgment } from '../../../utils/files/FilesUtil';
import { AttendeeType, File, NintendoAccountFamilyUser } from '../../../utils/types';
import FileTitle from '../../file/FileTitle';
import BlockHeader from './BlockHeader';
import ChildGuardianConsentBlock from './ChildGuardianConsentBlock';

type ConsentBlockProps = {
    userId: string;
    files: Array<File>;
    guests: Array<NintendoAccountFamilyUser>;
    header?: string;
    tooltip?: JSX.Element;
};

/**
 * Renders a checkbox for any files that the user has to read and acknowledge
 *
 * Any files that have their file_config ACKNOWLEDGEMENT config and its set to CHECKBOX, require the user to
 * check a box to confirm they have read and agreed to the document(s).
 *
 * Any child guests need the host of the party to acknowledge via another checkbox
 *
 * @param userId: id of the user logged in
 * @param files: the event files
 * @param guests - List of currently selected guests
 * @param header - The header text to render
 * @param tooltip optional tooltip JSX.Element
 */
const ConsentBlock = ({ userId, files, guests, header, tooltip }: ConsentBlockProps) => {
    const localizedStrings = useContext(LocalizationContext);
    const { formState, register } = useFormContext();

    // If none of the files require agreement, return null since there is nothing for the user to agree to
    const requiredFiles: Array<File> = getFilesThatRequireAcknowledgment(files);
    if (requiredFiles.length <= 0) {
        return null;
    }

    const formControlReferencePrefixRegistrant = `${generateFormControlReferencePrefix(
        userId,
        BlockType.CONSENT_BLOCK,
    )}.registrant`;
    const formControlReferencePrefixChild = `${generateFormControlReferencePrefix(
        userId,
        BlockType.CONSENT_BLOCK,
    )}.child`;

    const childrenGuests: Array<NintendoAccountFamilyUser> = [];
    guests.forEach((guest) => {
        if (getAttendeeType(guest.birthday) === AttendeeType.CHILD) {
            childrenGuests.push(guest);
        }
    });

    return (
        <Row data-testid="file-consent-block-component">
            <Col md={12}>
                <BlockHeader
                    icon={<i className="me-2 fas fa-file-alt" />}
                    title={header}
                    formState={formState}
                    formControlReferencePrefixes={[
                        formControlReferencePrefixRegistrant,
                        formControlReferencePrefixChild,
                    ]}
                    tooltip={tooltip}
                />

                <Container>
                    <Form.Check
                        className="mt-6 mb-6"
                        type="checkbox"
                        id={formControlReferencePrefixRegistrant}
                        {...register(formControlReferencePrefixRegistrant, { required: true })}
                        data-testid="adult-acknowledgement-checkbox"
                        label={localizedStrings.reservations.adultAcknowledgement}
                    />
                    <div className="form-check">
                        <div className="py-2">
                            {requiredFiles.map((file) => {
                                return (
                                    <div key={file.file_id}>
                                        <FileTitle file={file} enableInstruction />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <ChildGuardianConsentBlock
                        formControlReferencePrefix={formControlReferencePrefixChild}
                        childGuests={childrenGuests}
                    />
                </Container>
            </Col>
        </Row>
    );
};

export default ConsentBlock;
