import React, { useContext } from 'react';
import Countdown from 'react-countdown';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Alert from 'react-bootstrap/Alert';
import LocalizationContext from '../../context/LocalizationContext';

type Props = {
    message: string;
    expirationDate: string;
    expireGoTo: string;
};

/**
 * Component for controlling the countdown timer on the registration page(s).
 * @param isDrawing - Denotes if this registration is a drawing or not.
 * @param expirationDate - Time when the temp reservation ends.
 * @param expireGoTo - Denotes where to send the user if the timer runs out of time.
 */
const CountDownTimer = ({ message, expirationDate, expireGoTo }: Props) => {
    const localizedStrings = useContext(LocalizationContext);
    const navigate = useNavigate();

    const onComplete = () => {
        navigate(expireGoTo, {
            state: {
                rsvpTimeOut: true,
            },
        });
    };

    const formattedExpirationDate = moment(expirationDate).toISOString();

    return (
        <>
            {/* Section only for screen readers */}
            <Alert className="visually-hidden" data-testid="sr-timer">
                {localizedStrings.formatString(message, {
                    timeRemaining: localizedStrings.account.time,
                })}
            </Alert>
            {/* This section is aria-hidden so that the screen reader is not announcing every second of the countdown */}
            <div id="live-timer" aria-hidden="true" data-testid="live-timer">
                <Alert variant="warning" className="mt-1">
                    {localizedStrings.formatString(message, {
                        timeRemaining: (
                            <strong>
                                <span id="timer">
                                    <Countdown
                                        date={formattedExpirationDate}
                                        onComplete={onComplete}
                                        renderer={({ formatted }) => {
                                            return `${formatted.minutes}:${formatted.seconds}`;
                                        }}
                                    />
                                </span>{' '}
                                {localizedStrings.reservations.minutes}
                            </strong>
                        ),
                    })}
                </Alert>
            </div>
        </>
    );
};

export default CountDownTimer;
