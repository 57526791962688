import Moment from 'moment';
import { AttendeeType, PartyMember } from './types';

const ADULT = 18;

/**
 * Utility method to get the attendee type of a given Nintendo Account guest.
 * @param birthday - Birthday string in the format of "YYYY-MM-DD"
 */
export const getAttendeeType = (birthday: string | null): AttendeeType => {
    // Exit early as "Adult" if the birthday field is null.
    if (!birthday) {
        return AttendeeType.ADULT;
    }

    const age = Moment().diff(Moment(birthday, 'YYYY-MM-DD'), 'year');
    return age >= ADULT ? AttendeeType.ADULT : AttendeeType.CHILD;
};

/**
 * Gets the PartyMember's NA nickname or codex FULL_NAME. If neither are available, return null
 *
 * @param member PartyMember
 */
export const getMemberName = (member: PartyMember) => {
    if (member.nickname || member.participant_info?.FULL_NAME) {
        return member.nickname ? member.nickname : member.participant_info?.FULL_NAME;
    }
    return null;
};
