import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import {
    EventResponse,
    EventGateResponse,
    GrandPrixServiceError,
    DrawingResponse,
    DrawingTicket,
    BaseTicket,
} from '../../utils/types';
import LocalizationContext from '../../context/LocalizationContext';
import LocalizedDate from '../common/LocalizedDate';
import { getDrawingTickets, getEventGateTickets } from '../../api/ticketApi';
import AccountContext from '../../context/AccountContext';
import ErrorContext from '../../context/ErrorModalContext';
import { isAxiosError } from '../../api/ApiUtils';
import { getModalErrorMessage } from '../../utils/ErrorUtils';
import TicketButton from './TicketButton';
import { checkIfSameDay } from '../../utils/DateUtils';

type TicketCardProps = {
    event: EventResponse;
    eventGate: EventGateResponse;
    drawing?: DrawingResponse;
    drawingTicket?: DrawingTicket | null;
    baseTicket?: BaseTicket | null;
    hasMultipleTickets: boolean;
    className?: string;
    accessCode?: string;
};

/**
 * Component for rendering an individual event ticket card
 * @param event - event object
 * @param eventGate - event gate object
 * @param drawing - optional drawing object
 * @param drawingTicket - optional Drawing Ticket associated with this Card
 * @param baseTicket - optional Base Ticket associated with this Card
 * @param hasMultipleTickets - whether we are rendering multiple event gate tickets or not
 * @param className - Optional CSS classes
 * @param accessCode - Optional Access Code string
 */
const TicketCard = ({
    event,
    eventGate,
    drawing,
    drawingTicket,
    baseTicket,
    hasMultipleTickets,
    className,
    accessCode,
}: TicketCardProps) => {
    const localizedStrings = useContext(LocalizationContext);
    const errorContext = useContext(ErrorContext);

    const navigate = useNavigate();
    const { value: accountInfo } = useContext(AccountContext);
    const [loading, setLoading] = useState(false);

    // Only show the end date and start and end time if the event start and end date are on the same day
    const isSameDay = checkIfSameDay(
        eventGate.start_date,
        eventGate.end_date,
        event.location.time_zone,
    );
    const getTickets = async () => {
        if (!accountInfo.token) {
            return;
        }

        setLoading(true);

        try {
            const tickets = drawing
                ? await getDrawingTickets(drawing.drawing_id, accountInfo.token)
                : await getEventGateTickets(eventGate.event_gate_id, accountInfo.token, accessCode);

            navigate(`/events/${event.event_id}/event_gates/${eventGate.event_gate_id}`, {
                state: {
                    accessCode,
                    event,
                    location: event.location,
                    gate: eventGate,
                    drawing,
                    tickets,
                },
            });
        } catch (e) {
            setLoading(false);
            if (isAxiosError(e) && e.response && e.response.data) {
                const grandPrixError = e.response.data as GrandPrixServiceError;
                errorContext.updateMessage(getModalErrorMessage(grandPrixError, localizedStrings));
            } else {
                errorContext.updateMessage(localizedStrings.error.serviceErrorModal.unknown);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Card className={`gate-ticket h-100 ${className || ''}`}>
            <Card.Body className="pt-2 pb-3">
                <h5 className="py-3 gate" data-testid="ticket-title">
                    {eventGate.description}
                </h5>
                <div className="date">
                    <LocalizedDate
                        date={eventGate.start_date}
                        format={localizedStrings.dates.eventTickets.dateRange}
                        timezone={event.location.time_zone}
                    />
                    {!isSameDay ? (
                        <>
                            {' '}
                            -<br />
                            <LocalizedDate
                                date={eventGate.end_date}
                                format={localizedStrings.dates.eventTickets.dateRange}
                                timezone={event.location.time_zone}
                            />
                        </>
                    ) : null}
                </div>
                {isSameDay ? (
                    <div className="time">
                        <LocalizedDate
                            date={eventGate.start_date}
                            format={localizedStrings.dates.eventTickets.time}
                            timezone={event.location.time_zone}
                        />
                        {' - '}
                        <LocalizedDate
                            date={eventGate.end_date}
                            format={localizedStrings.dates.eventTickets.time}
                            timezone={event.location.time_zone}
                        />{' '}
                        <LocalizedDate
                            date={eventGate.end_date}
                            format={localizedStrings.dates.eventTickets.timeZone}
                            timezone={event.location.time_zone}
                        />
                    </div>
                ) : null}

                <div className="event-entry-type mt-3 mb-1 text-muted small d-md-block">
                    <i className="fas fa-ticket-alt me-1" />{' '}
                    {drawing
                        ? localizedStrings.drawing.drawing
                        : localizedStrings.drawing.firstComeFirstServed}
                </div>
            </Card.Body>
            <Card.Footer className="bg-transparent border-top-0">
                <TicketButton
                    event={event}
                    eventGate={eventGate}
                    drawing={drawing}
                    nintendoId={accountInfo.userId}
                    onClick={getTickets}
                    loading={loading}
                    drawingTicket={drawingTicket}
                    baseTicket={baseTicket}
                    hasMultipleTickets={hasMultipleTickets}
                />
            </Card.Footer>
        </Card>
    );
};

export default TicketCard;
