import React, { useContext } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useFormContext } from 'react-hook-form';
import LocalizationContext from '../../../context/LocalizationContext';
import { isInternal } from '../../../utils/InternalUtils';
import { AttendeeType } from '../../../utils/types';
import './FieldStyles.css';

type StaticNameFieldProps = {
    userId: string;
    name: string;
    attendeeType: AttendeeType;
};

/**
 * Component for rendering a birthday readonly field
 *
 * @param userId userId of the user logged in
 * @param name name to display
 * @param attendeeType AttendeeType, used to determine if we show the full name or just nickname
 */
const ReadOnlyNameField = ({ userId, name, attendeeType }: StaticNameFieldProps) => {
    const localizedStrings = useContext(LocalizationContext);
    const { register } = useFormContext();

    return (
        <Form.Group as={Row}>
            <Col xs={3}>
                <Form.Label className="mt-2" htmlFor={`${userId}-name`}>
                    {!isInternal(localizedStrings)
                        ? localizedStrings.reservations.nickname
                        : localizedStrings.events.dataAttribute.FULL_NAME}
                </Form.Label>
            </Col>
            <Col className="name-field-user-name">
                <Form.Control
                    className="name-field-spaced-text"
                    id={`${userId}-name`}
                    plaintext
                    readOnly
                    {...register(`participant.${userId}`, {
                        value:
                            attendeeType === AttendeeType.ADULT
                                ? name
                                : `${name} (${localizedStrings.tickets.registration.guest.child})`,
                    })}
                />
            </Col>
        </Form.Group>
    );
};

export default ReadOnlyNameField;
