import React from 'react';
import { BaseTicket, DrawingTicket, TicketTypeResponse } from '../utils/types';

export type MyTicketsByTypeMap = Map<TicketTypeResponse, Array<DrawingTicket | BaseTicket>>;

type GlobalMyTickets = {
    value: MyTicketsByTypeMap;
    loading: boolean;
    refresh: () => void;
};

export default React.createContext<GlobalMyTickets>({
    value: new Map(),
    loading: false,
    refresh: () => {},
});
