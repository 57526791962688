import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { TicketResource, TicketTypeResponse } from '../utils/types';
import { getMyTickets } from '../api/ticketApi';
import AccountContext from './AccountContext';
import MyTicketsContext, { MyTicketsByTypeMap } from './MyTicketsContext';

type Props = {
    children: string | JSX.Element | Array<JSX.Element>;
};
const MyTicketsContextProvider = ({ children }: Props) => {
    const { value: accountInfo } = useContext(AccountContext);

    // My Activities states
    const [myTickets, setMyTickets] = useState<MyTicketsByTypeMap>(new Map());
    const [loadingMyTickets, setLoadingMyTickets] = useState(false);
    const userIdRef = useRef(accountInfo.userId);

    // Setup helper function
    const refreshMyTickets = async () => {
        const responseMap = new Map();
        setLoadingMyTickets(true);
        myTickets.clear();

        // Call getMyTickets for each ticket type and store the response in myTickets mapped by MyTicketTypeResponse key
        // The promise all allows us to retrieve tickets for each ticket type in parallel
        // The map operation groups the response data by ticket type
        await Promise.all(
            Object.values(TicketResource).map(async (ticketType: TicketResource) => {
                // Use TicketResource
                const updatedTicketsForType = await getMyTickets(ticketType, accountInfo.token);
                if (updatedTicketsForType) {
                    // Store resulting tickets with a TicketTypeResponse value
                    responseMap.set(
                        drawingRequestToResponseType(ticketType),
                        updatedTicketsForType,
                    );
                }
                return updatedTicketsForType;
            }),
        );
        setMyTickets(responseMap);
        setLoadingMyTickets(false);
    };

    useEffect(() => {
        if (accountInfo.token && myTickets.size === 0) {
            setLoadingMyTickets(true);
            refreshMyTickets();
        } else if (accountInfo.userId !== userIdRef.current) {
            myTickets.clear();
            userIdRef.current = accountInfo.userId;
        }
    }, [accountInfo.token, accountInfo.userId]);

    const ticketMemo = useMemo(
        () => ({
            value: myTickets,
            loading: loadingMyTickets,
            refresh: refreshMyTickets,
        }),
        [accountInfo.token, loadingMyTickets, myTickets?.size],
    );

    return <MyTicketsContext.Provider value={ticketMemo}>{children}</MyTicketsContext.Provider>;
};

/**
 * Helper method for converting a TicketResource to a TicketTypeResponse.
 * TicketResource: is used as a path param in the request (e.g. /me/{TicketResource}/tickets)
 * TicketTypeResponse: is the "details.type" value returned in the JSON
 * @param ticketType TicketResource
 * @return TicketTypeResponse
 */
const drawingRequestToResponseType = (ticketType: TicketResource): TicketTypeResponse => {
    switch (ticketType) {
        case TicketResource.DRAWINGS:
            return TicketTypeResponse.DRAWING_TICKET;
        case TicketResource.EVENT_GATES:
            return TicketTypeResponse.EVENT_GATE_TICKET;
        case TicketResource.TIME_SLOTS:
            return TicketTypeResponse.TIME_SLOT_TICKET;
        default:
            throw new Error();
    }
};

export default MyTicketsContextProvider;
