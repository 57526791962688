import {
    BaseTicket,
    DetailFormErrors,
    DrawingTicket,
    GrandPrixServiceError,
    Reservation,
    Ticket,
} from './types';

/** *
 * Type guard function to determine if the object is a Ticket or a Reservation.
 * @param item - Reservation or Ticket
 */
export const isReservation = (item: Reservation | Ticket): item is Reservation => {
    return (item as Reservation).temporary_reservation_expires !== undefined;
};

/**
 * Type guard for Drawing tickets
 * @param ticket either a BaseTicket or a DrawingTicket
 * @return true if this is a DrawingTicket, false otherwise
 */
export const isDrawingTicket = (ticket: BaseTicket | DrawingTicket): ticket is DrawingTicket => {
    return (ticket as DrawingTicket).outcomes !== undefined;
};

/**
 * Type guard for BaseTickets
 * @param ticket BaseTicket or DrawingTicket
 * @return true if ticket is a BaseTicket
 */
export const isBaseTicket = (ticket: BaseTicket | DrawingTicket): ticket is BaseTicket => {
    return (ticket as DrawingTicket).outcomes === undefined;
};

/**
 * Type guard for GrandPrixServiceError object
 * @param item - Item to check
 */
export const isErrorResponse = <T>(item: any): item is GrandPrixServiceError<T> => {
    const errorCode: string = (item as GrandPrixServiceError<T>).code;
    return errorCode !== undefined && errorCode.startsWith('GP');
};

/**
 * Type guard for GrandPrixServiceError object and seeing if this is a form error response or not.
 * Important: isErrorResponse and isFormErrorResponse will both return true so type checking order is important.
 * @param item - Item to check
 */
export const isFormErrorResponse = (item: any): item is GrandPrixServiceError<DetailFormErrors> => {
    return (
        isErrorResponse(item) &&
        (item as GrandPrixServiceError<DetailFormErrors>).details &&
        Object.keys((item as GrandPrixServiceError<DetailFormErrors>).details).length > 0 &&
        (item as GrandPrixServiceError<DetailFormErrors>).details.constructor === Object
    );
};
