import axios, { AxiosRequestHeaders, AxiosResponse } from 'axios';
import {
    BaseTicket,
    ConfirmTicketsRequest,
    DetailFormErrors,
    DrawingTicket,
    GrandPrixServiceError,
    Ticket,
    TicketResource,
} from '../utils/types';
import { checkExceptionForGrandPrixError } from '../utils/ErrorUtils';
import { audienceQueryParam, getHeaders } from './ApiUtils';

/**
 * Call the ticket creation API to create tickets for the specified resource
 * @param resourceType {TicketResource} - The resource type to create ticket(s) for. Ex) event_gates
 * @param resourceId {string} - The ID of the resource to create ticket(s) for
 * @param token {string} - The user's access token
 * @param accessCode - Optional access code for access code-guarded Event Gates
 * @returns Returns an array of tickets
 */
const getTickets = async (
    resourceType: TicketResource,
    resourceId: string | number,
    token: string,
    accessCode?: string,
): Promise<Array<Ticket>> => {
    const headers = getHeaders(token) as AxiosRequestHeaders;

    const apiUri = `/attendee/${resourceType}/${resourceId}/tickets`;

    const response: AxiosResponse<Array<Ticket>> = await axios.post(
        apiUri,
        accessCode
            ? {
                  access_code: accessCode,
              }
            : {},
        { headers },
    );

    return response.data;
};

/**
 * Confirm these Tickets
 * @param resourceType one of TicketResource
 * @param resourceId the ID for this resource
 * @param token users token
 * @param locale the locale to use for notifications
 * @param confirmRequest a ConfirmTicketsRequest object
 */
export const confirmTickets = async (
    resourceType: TicketResource,
    resourceId: string | number,
    token: string,
    locale: string,
    confirmRequest: ConfirmTicketsRequest,
): Promise<Array<Ticket> | GrandPrixServiceError<Array<string> | DetailFormErrors>> => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Accept-Language': locale,
    };

    const apiUri = `/attendee/${resourceType}/${resourceId}/tickets`;

    try {
        const response: AxiosResponse<Array<Ticket>> = await axios.put(apiUri, confirmRequest, {
            headers,
        });
        return response.data;
    } catch (e) {
        return checkExceptionForGrandPrixError(e);
    }
};

/**
 * Call the ticket creation API to create tickets for an event gate
 * @param eventGateId {number} - The event gate's event_gate_id
 * @param token {string} - The user's access token
 * @param accessCode - optional Access Code
 * @returns Returns an array of tickets
 */
export const getEventGateTickets = async (
    eventGateId: number,
    token: string,
    accessCode?: string,
): Promise<Array<Ticket>> => {
    return getTickets(TicketResource.EVENT_GATES, eventGateId, token, accessCode);
};

/**
 * Call the ticket creation API to create tickets for a drawing
 * @param drawingId {string} - The drawing's drawing_id
 * @param token {string} - The user's access token
 * @returns Returns an array of tickets
 */
export const getDrawingTickets = async (
    drawingId: string,
    token: string,
): Promise<Array<Ticket>> => {
    return getTickets(TicketResource.DRAWINGS, drawingId, token);
};

/**
 * Retrieve tickets for the specific ticket type
 * /attendee/me/{resourceType}/tickets
 * @param ticketType TicketResource
 * @param token Users bearer token
 */
export const getMyTickets = async (
    ticketType: TicketResource,
    token: string,
): Promise<Array<DrawingTicket | BaseTicket> | null> => {
    const apiUri = `/attendee/me/${ticketType}/tickets`;

    try {
        const response: AxiosResponse<Array<DrawingTicket | BaseTicket>> = await axios.get(apiUri, {
            params: audienceQueryParam,
            headers: getHeaders(token) as AxiosRequestHeaders,
        });
        return response.data;
    } catch (e) {
        return null;
    }
};

/**
 * API function for getting an iOS wallet pass for a ticket
 *
 * @param resourceType - Type of resource this ticket is for
 * @param resourceId - ID of the resource this ticket is for
 * @param ticketId ID of the ticket
 * @param token Token provided by JWT
 * @return Blob | null - Returns a blob if the data is generated. Returns null otherwise.
 */
export const getTicketWalletPass = async (
    resourceType: string,
    resourceId: string,
    ticketId: string,
    token: string,
) => {
    const apiUrl = `/attendee/me/${resourceType}/${resourceId}/tickets/${ticketId}/apple-wallet-pass`;

    try {
        const headers = getHeaders(token) as AxiosRequestHeaders;
        const response: AxiosResponse<Blob> = await axios.get(apiUrl, {
            headers,
            data: {},
            responseType: 'blob',
        });
        return response.data;
    } catch (e) {
        return null;
    }
};

/**
 * API function for getting add to calendar links
 *
 * @param ticketResourceType - Type of resource this ticket is for; i.e. event-gates
 * @param ticketResourceId - ID of the resource this ticket is for.
 * @param ticketId - ID of the ticket
 * @param token - Token provided by JWT
 * @return JSON | null - return a blob if the data is return. Null otherwise
 */
export const getTicketCalendarLinks = async (
    ticketResourceType: string,
    ticketResourceId: string,
    ticketId: string,
    token: string,
) => {
    const apiUri = `/attendee/me/${ticketResourceType}/${ticketResourceId}/tickets/${ticketId}/calendars/links`;

    try {
        const headers = getHeaders(token) as AxiosRequestHeaders;
        const response: AxiosResponse = await axios.get(apiUri, {
            headers,
        });
        return response.data;
    } catch (e) {
        return null;
    }
};

/**
 * API function for canceling a ticket.
 *
 * @param token - Token provided by JWT
 * @param ticketResourceType - Type of resource this ticket is for; i.e. event-gates
 * @param ticketId - ID of the ticket
 * @param partyId - ID of the party this ticket is for
 * @param participantId - Participant who we want to cancel the ticket for
 * @return boolean - True if cancel succeeded; false otherwise
 */
export const cancelTicket = async (
    token: string,
    ticketResourceType: string,
    ticketId: string,
    partyId: string,
    participantId: number,
): Promise<boolean> => {
    const apiUri = `attendee/${ticketResourceType}/tickets/${ticketId}/parties/${partyId}/participants/${participantId}`;
    try {
        const headers = getHeaders(token) as AxiosRequestHeaders;
        const response: AxiosResponse = await axios.delete(apiUri, {
            headers,
        });
        return response.status === 200;
    } catch (e) {
        return false;
    }
};
