import React from 'react';

type Props = {
    content: string | React.ReactNode;
};

const ToastContent = ({ content }: Props) => {
    return <span>{content}</span>;
};

export default ToastContent;
