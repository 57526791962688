import React, { useContext } from 'react';
import { Tooltip } from 'react-tooltip';
import moment from 'moment';
import Loading from 'react-loading';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import {
    AttendeeType,
    BaseTicket,
    DrawingResponse,
    DrawingStatus,
    DrawingTicket,
    EventGateResponse,
    EventResponse,
    MyTicketsRerouteState,
    MyTicketTab,
    TicketStatus,
} from '../../utils/types';
import LocalizationContext from '../../context/LocalizationContext';
import { formatDateTime, generateTooltipId } from '../../utils/FormattingUtils';
import './TicketButton.css';
import AccountContext from '../../context/AccountContext';
import { getAttendeeType } from '../../utils/AccountUtils';
import {
    ButtonData,
    createButton,
    createDefaultButton,
    createDisabledButton,
    createEnabledButton,
} from '../account/myTicketsAndPasses/MyTicketUtils';

type TicketButtonProps = {
    event: EventResponse;
    eventGate: EventGateResponse;
    drawing?: DrawingResponse;
    nintendoId: string;
    onClick: () => {};
    loading: boolean;
    drawingTicket?: DrawingTicket | null;
    baseTicket?: BaseTicket | null;
    hasMultipleTickets: boolean;
};

/**
 * Renders a button depending on the state of this users entry or the state of the event gate
 *
 * @param props
 *  - event: Event data
 *  - eventGate: Event Gate data
 *  - drawing: optional DrawingResponse
 *  - nintendoId: NA identifier
 *  - onClick: callback for this button
 *  - loading: boolean to know if we are loading data or not
 *  - drawingTicket: drawing ticket for user
 *  - baseTicket: base ticket for user
 *  - hasMultipleTicket: whether we are rendering multiple event gate tickets or not
 */
const TicketButton = (props: TicketButtonProps) => {
    const { event, eventGate, drawing, drawingTicket, baseTicket } = props;
    const localizedStrings = useContext(LocalizationContext);
    const { value: accountInfo, setValue } = useContext(AccountContext);
    const navigate = useNavigate();

    const { registration_start_date, registration_end_date } = drawing || eventGate;
    const spaceAvailable = drawing ? true : eventGate.space_available;

    /**
     * Go to My Tickets/Passes page
     */
    const viewEntryOnClick = () => {
        const ticketRouteState: MyTicketsRerouteState = {
            displayTab: MyTicketTab.EVENT_GATE,
        };

        navigate('/my-tickets-passes', {
            state: ticketRouteState,
        });
    };

    /**
     * Goto My Tickets/Passes details page
     */
    const viewTicketsOnClick = () => {
        navigate(`/my-tickets-passes/event_gates/${eventGate.event_gate_id}`, {
            state: {},
        });
    };

    /**
     * Helper method for creating tooltip for buttons
     * @param tooltipString - string for the tooltip
     * @param date - start or end date depending on your needs
     */
    const registrationTooltip = (tooltipString: string, date: string) => {
        return localizedStrings.formatString(
            tooltipString,
            formatDateTime(date, localizedStrings.dates.dateRangeSecond, event.location.time_zone),
            formatDateTime(
                date,
                localizedStrings.dates.hourMinutesTimezone,
                event.location.time_zone,
            ),
        );
    };

    const notAuthed = !props.nintendoId;
    let buttonData: ButtonData = createDefaultButton(props.onClick);

    if (notAuthed) {
        if (moment().isAfter(registration_end_date)) {
            // After registration ended
            buttonData = createDisabledButton(
                localizedStrings.tickets.registrationClosed,
                registrationTooltip(
                    localizedStrings.tickets.registrationAlreadyClosed,
                    registration_end_date,
                ),
                `registration-closed-button-${eventGate.event_gate_id}`,
                'danger',
            );
        } else if (moment().isBefore(registration_start_date)) {
            // Before registration started
            buttonData = createDisabledButton(
                localizedStrings.tickets.comingSoon,
                registrationTooltip(
                    localizedStrings.tickets.registrationNotStarted,
                    registration_start_date,
                ),
                `registration-opens-button-${eventGate.event_gate_id}`,
                'secondary',
            );
        } else if (!spaceAvailable) {
            // Sold out
            buttonData = createButton(
                true,
                localizedStrings.tickets.soldOut,
                null,
                'danger',
                '',
                '',
                `sold-out-button-${eventGate.event_gate_id}`,
            );
        } else {
            buttonData = createEnabledButton(
                () => setValue({ ...accountInfo, showModal: true }),
                localizedStrings.tickets.getTickets,
                null,
                `auth-required-modal-button-${eventGate.event_gate_id}`,
            );
        }
    } else if (
        drawing &&
        drawingTicket &&
        !baseTicket &&
        drawing.drawing_id === drawingTicket.details.value
    ) {
        // We are rendering a drawing ticket
        // If drawingTicket matches the drawing.drawing_id render the appropriate button based on the
        // status of the users myTicket drawing
        if (drawingTicket.details.drawing_status === DrawingStatus.SELECTED) {
            // We won! View Tickets link
            buttonData = createEnabledButton(
                viewTicketsOnClick,
                localizedStrings.tickets.viewTickets,
                null,
                `view-tickets-${eventGate.event_gate_id}`,
            );
        } else if (drawingTicket.details.drawing_status === DrawingStatus.NOT_SELECTED) {
            // We lost! Disable the button
            buttonData = createDisabledButton(
                localizedStrings.tickets.registrationClosed,
                registrationTooltip(
                    localizedStrings.tickets.registrationAlreadyClosed,
                    registration_end_date,
                ),
                `registration-closed-button-${eventGate.event_gate_id}`,
                'danger',
            );
        } else if (drawingTicket.details.drawing_status === DrawingStatus.PENDING) {
            // Still waiting for drawing to run. View Entry link
            buttonData = createEnabledButton(
                viewEntryOnClick,
                localizedStrings.tickets.viewEntry,
                null,
                `view-entry-${eventGate.event_gate_id}`,
            );
        } else {
            return null;
        }
    } else if (drawingTicket && !baseTicket) {
        // The drawing is over and we either won or lost, render button for other event gates accordingly
        if (drawingTicket.details.drawing_status === DrawingStatus.SELECTED) {
            // Drawing ran, but user was a winner, show registration closed since the user can't select
            // this Drawing ticket anymore.
            buttonData = createDisabledButton(
                localizedStrings.tickets.registrationClosed,
                registrationTooltip(
                    localizedStrings.tickets.registrationAlreadyClosed,
                    registration_end_date,
                ),
                `registration-closed-button-${eventGate.event_gate_id}`,
                'danger',
            );
        } else if (drawingTicket.details.drawing_status === DrawingStatus.PENDING) {
            // Drawing hasn't run yet. Disable the button but tell the user they can cancel their other ticket and
            // select a new one
            buttonData = createDisabledButton(
                localizedStrings.tickets.getTickets,
                localizedStrings.tickets.cancelOtherTicket,
                `cancel-other-ticket-button-${eventGate.event_gate_id}`,
                'secondary',
            );
        } else if (drawingTicket.details.drawing_status === DrawingStatus.NOT_SELECTED) {
            // Drawing ran, but user was not a winner, allow user to register for a FCFS ticket
            // Check registration date and display Registration Closed if after registration date
            if (moment().isAfter(registration_end_date)) {
                buttonData = createDisabledButton(
                    localizedStrings.tickets.registrationClosed,
                    registrationTooltip(
                        localizedStrings.tickets.registrationAlreadyClosed,
                        registration_end_date,
                    ),
                    `registration-closed-button-${eventGate.event_gate_id}`,
                    'danger',
                );
                // Check registration date and display Coming Soon if before registration date
            } else if (moment().isBefore(registration_start_date)) {
                buttonData = createDisabledButton(
                    localizedStrings.tickets.comingSoon,
                    registrationTooltip(
                        localizedStrings.tickets.registrationNotStarted,
                        registration_start_date,
                    ),
                    `registration-opens-button-${eventGate.event_gate_id}`,
                    'secondary',
                );
                // Check space availability
            } else if (!spaceAvailable) {
                buttonData = createButton(
                    true,
                    localizedStrings.tickets.soldOut,
                    null,
                    'danger',
                    '',
                    '',
                    `sold-out-button-${eventGate.event_gate_id}`,
                );
                // We have multiple event gate tickets rendering, if it is a drawing ticket disable Get Ticket
            } else if (props.hasMultipleTickets && eventGate.drawing_required) {
                buttonData = createDisabledButton(
                    localizedStrings.tickets.getTickets,
                    localizedStrings.tickets.cancelOtherTicket,
                    `cancel-other-ticket-button-${eventGate.event_gate_id}`,
                    'secondary',
                );
                // Enable get Ticket button for valid FCFS ticket
            } else {
                buttonData = createEnabledButton(
                    props.onClick,
                    localizedStrings.tickets.getTickets,
                    null,
                    `get-tickets-button-${eventGate.event_gate_id}`,
                );
            }
        } else {
            return null;
        }
    } else if (baseTicket && baseTicket.details.value === eventGate.event_gate_id.toString()) {
        // We have a non-drawing ticket to render
        if (baseTicket.party.members[0].ticket.status === TicketStatus.CONFIRMED) {
            // User has a ticket, allow them to view it
            buttonData = createEnabledButton(
                viewTicketsOnClick,
                localizedStrings.tickets.viewTickets,
                null,
                `view-tickets-${eventGate.event_gate_id}`,
            );
        } else if (
            baseTicket.party.members[0].ticket.status === TicketStatus.CANCELED ||
            baseTicket.party.members[0].ticket.status === TicketStatus.TEMPORARY ||
            baseTicket.party.members[0].ticket.status === TicketStatus.EXPIRED
        ) {
            // Allow the user to getTickets again
            buttonData = createEnabledButton(
                props.onClick,
                localizedStrings.tickets.getTickets,
                null,
                `view-entry-${eventGate.event_gate_id}`,
            );
        } else {
            return null;
        }
    } else if (!spaceAvailable) {
        // Sold out
        buttonData = createButton(
            true,
            localizedStrings.tickets.soldOut,
            null,
            'danger',
            '',
            '',
            `sold-out-button-${eventGate.event_gate_id}`,
        );
    } else if (
        moment().isAfter(registration_start_date) &&
        moment().isBefore(registration_end_date)
    ) {
        // During registration
        const isChildAccount = getAttendeeType(accountInfo.birthday) === AttendeeType.CHILD;
        if (isChildAccount) {
            // Child needs Adult to signup for this event gate
            buttonData = createDisabledButton(
                localizedStrings.tickets.adultRequired,
                localizedStrings.tickets.adultRequiredTooltip,
                `adult-required-button-${eventGate.event_gate_id}`,
                'secondary',
            );
        } else if (!drawingTicket && !baseTicket) {
            // If we don't have a ticket yet, allow for registration
            buttonData = createEnabledButton(
                props.onClick,
                localizedStrings.tickets.getTickets,
                null,
                `get-tickets-button-${eventGate.event_gate_id}`,
            );
        } else if (props.hasMultipleTickets) {
            // We have multiple tickets to render
            buttonData = createDisabledButton(
                localizedStrings.tickets.getTickets,
                localizedStrings.tickets.cancelOtherTicket,
                `cancel-other-ticket-button-${eventGate.event_gate_id}`,
                'secondary',
            );
        } else {
            return null;
        }
    } else if (moment().isAfter(registration_end_date)) {
        // After registration ended
        buttonData = createDisabledButton(
            localizedStrings.tickets.registrationClosed,
            registrationTooltip(
                localizedStrings.tickets.registrationAlreadyClosed,
                registration_end_date,
            ),
            `registration-closed-button-${eventGate.event_gate_id}`,
            'danger',
        );
    } else if (moment().isBefore(registration_start_date)) {
        // Before registration started
        buttonData = createDisabledButton(
            localizedStrings.tickets.comingSoon,
            registrationTooltip(
                localizedStrings.tickets.registrationNotStarted,
                registration_start_date,
            ),
            `registration-opens-button-${eventGate.event_gate_id}`,
            'secondary',
        );
    } else {
        return null;
    }

    const button = (
        <Button
            variant={buttonData.variant}
            className="w-100 ticket-button"
            disabled={props.loading || buttonData.disabled}
            onClick={buttonData.onClickMethod}
            data-testid={buttonData.dataTestId}
            aria-label={`${eventGate.description} ${buttonData.label}`}
        >
            {props.loading ? (
                <Loading
                    color="#FFFFFF"
                    data-testid="loading-tickets-spinner"
                    type="spin"
                    height={20}
                    width={20}
                    className="ms-2 me-2"
                />
            ) : (
                <>
                    {buttonData.label}{' '}
                    <i id={buttonData.iconId} className={`${buttonData.iconClass} fas-ticket`} />
                </>
            )}
        </Button>
    );

    const tooltipId = generateTooltipId();

    return (
        <>
            {buttonData.tooltip != null && buttonData.tooltip.length > 0 ? (
                <>
                    <Tooltip id={tooltipId} className="ticket-tooltip" />
                    <span
                        className="d-block"
                        data-tooltip-id={tooltipId}
                        data-placement="top"
                        data-tooltip-content={buttonData.tooltip.toString()}
                        data-testid={`tooltip-${eventGate.event_gate_id}`}
                    >
                        {button}
                    </span>
                </>
            ) : (
                <>{button}</>
            )}
        </>
    );
};

export default TicketButton;
