import get from 'lodash.get';
import { FieldValues, FormState } from 'react-hook-form';

/**
 * List of well known component, block types. These are used to help uniquely identify which form controls are in
 * which block. Useful for knowing which block is in error and reporting the error under that block.
 */
export enum BlockType {
    CONSENT_BLOCK = 'CONSENT_BLOCK',
    DETAILS_BLOCK = 'DETAILS_BLOCK',
    GUESTS_BLOCK = 'GUESTS_BLOCK',
    REGISTRANT_BLOCK = 'REGISTRANT_BLOCK',
    ATTENDEES_BLOCK = 'ATTENDEES_BLOCK',
}

/**
 * Generates a consistent form control reference prefix id. This is used with react-hook-forms "register" method,
 * so we can target each "block" and any of the blocks input fields for any errors.
 *
 * @param userId uniquest id of the user that is logged in and filling our the form
 * @param blockType BlockType which defines which block this referenceId is associated with
 * @return string standard reference id prefix
 */
export const generateFormControlReferencePrefix = (userId: string, blockType: BlockType) => {
    return `user.${userId}.${blockType}`;
};

/**
 * Checks the "formState.errors" object and sees if there are any errors for the given referenceIds
 *
 * @param formState react-hook-form FormState object
 * @param referenceIds list of referenceIds to search "formState.errors" for
 * @return boolean: if errors are found for any of the referenceIds
 */
export const hasFormErrors = (formState: FormState<FieldValues>, referenceIds: Array<string>) => {
    return (
        referenceIds.find((referenceId: string) => {
            return get(formState.errors, referenceId) !== undefined;
        }) !== undefined
    );
};

/**
 * Returns the proper component based on if any of the referenceIds contain errors in "formState.errors" object.
 *
 * @param formState react-hook-form FormState object
 * @param referenceIds list of referenceIds to search "formState.errors" for
 * @param errorComponent JSX.Element to return if errors are found
 * @param nonErrorComponent JSX.Element to return if no errors are found
 * @return errorComponent if error is found or nonErrorComponent if no errors are found
 */
export const chooseFormResultComponentAfterSubmit = (
    formState: FormState<FieldValues>,
    referenceIds: Array<string>,
    errorComponent: JSX.Element,
    nonErrorComponent: JSX.Element,
): JSX.Element | undefined => {
    const hasErrors = hasFormErrors(formState, referenceIds);
    if (formState.isSubmitted && hasErrors) {
        return errorComponent;
    }

    if (formState.isSubmitted && !hasErrors) {
        return nonErrorComponent;
    }

    return undefined;
};
