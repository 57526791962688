const en = {
    general: {
        backToActivityTimes: 'Back to Activity Times',
        backToActivityDetails: 'Back to Activity Details',
        backToEvent: 'Back to Event',
        location: 'Location',
        rsvp: 'RSVP',
        entry: 'entry',
        soldOut: 'SOLD OUT',
        cancel: 'Cancel',
        close: 'Close',
        confirm: 'Confirm',
        exit: 'Exit',
        signInCreateAccount: 'Sign In / Create Account',
        signIn: 'sign in',
        warpPipePass: 'Warp Pipe Passes',
        learnMore: 'More Info',
        currentLanguage: 'English',
        yes: 'YES',
        no: 'NO',
        note: 'NOTE',
        addressFormat: '{address}, {city}, {region} {postal_code}',
    },
    tabs: {
        events: 'Events',
        myRSVPs: 'My Entries / RSVPs',
        myTicketsAndPasses: 'My Tickets / Passes',
        checkIn: 'Check In',
        signIn: 'Sign In to Nintendo Account',
    },
    events: {
        eventItemHeader: 'Event:',
        upcoming: 'Upcoming Events',
        moreInfo: 'More Info',
        eventDetails: 'Event Details',
        viewEventDetails: 'View Event Details',
        details: {
            tabs: {
                tickets: 'Tickets',
                activities: 'Activities',
                resources: 'Resources',
            },
            accessCode: {
                aria: {
                    accessCodeInput: 'Access Code',
                    exitButton: 'Exit access code pane',
                },
                title: 'Enter your access code',
                toggleButtonLabel: 'Redeem an access code',
                redeemButtonLabel: 'Redeem',
                inputPlaceHolder: '16-Digit access code',
                enterDifferentCode: 'Enter a different access code',
                success: {
                    woohoo: 'Woohoo!',
                    youCanRegister: 'You can now register for this:',
                },
                error: {
                    invalid: 'Invalid code. Please try again.',
                    registrationClosed: 'Registration for this Ticket has closed',
                },
            },
        },
        dataAttribute: {
            FULL_NAME: 'Legal Name',
            FIRST_NAME: 'First Name',
            LAST_NAME: 'Last Name',
            POSTAL_CODE: 'ZIP/Postal Code',
            PHONE: 'Telephone Number',
            US_ADDRESS: 'Mailing Address',
            MAILING_ADDRESS: {
                streetAddress: 'Street Address',
                aptOrSuite: 'Apt/Suite',
                city: 'City',
                state: 'State',
                country: 'Country',
                zipCode: 'ZIP Code',
            },
            OPT_IN: 'Opt-in to e-mail sharing',
            ACCESS_CODE: 'Access Code',
        },
        ageRatingUrl: 'https://www.esrb.org/ratings-guide',
    },
    activities: {
        activities: 'Activities',
        viewActivities: 'View Activities',
        registrationNotBegun: 'Registration has not started for this activity',
        registrationHasEnded: 'Registration has ended for this activity',
        registrationBegins: 'Registration begins',
        details: {
            title: 'Activity Details',
            registrationBegins: 'Registration Begins:',
            registrationEnds: 'Registration Ends:',
            header: {
                noAvailableTimes: 'Sorry, there are no available times.',
            },
            continueToRSVP: 'Continue',
            viewMy: 'View My {0}',
            alreadyRSVPd: 'You already have a Warp Pipe Pass for this activity.',
            ticketIsRequired:
                'A Ticket to this Event is required before signing up for this Activity. {link}.',
            eventTicketRequired: 'Event Ticket required',
            ticketIsPendingDrawing:
                'Your Event Ticket is still pending a drawing. Check back again after the drawing has occurred.',
            checkForTicketAvailability: 'Check here for Ticket availability',
            label: {
                startDate: 'Start date is {startDate}',
                activityType: 'Activity type is {activityType}',
            },
            iconLabel: {
                activityTime: 'Icon for activity time',
                activityType: 'Icon for activity type',
                registration: 'Icon for registration type',
                location: 'Icon for location',
            },
        },
        timeSlots: {
            title: 'RSVP Details',
            noTimesForDay: 'There are no times available for this day.',
        },
        unknownActivityType: 'Unknown Activity Type',
        types: {
            GAME_DEMO: 'Game Demo',
            GENERAL_CHECK_IN: 'General Check-In',
            COMPETITIVE_PLAY: 'Competitive Play',
            EXCLUSIVE_OFFER: 'Exclusive Offer',
            LEAD_GENERATION: 'Lead Generation',
            MEET_GREET: 'Meet & Greet',
            PRODUCT_LAUNCH: 'Product Launch',
            SPECIAL_EVENT: 'Special Event',
            unknown: 'Unknown Activity',
        },
    },
    reservations: {
        timeRemainingForReservation:
            'Time Remaining: {timeRemaining}. If you leave this page or if time runs out before you complete the registration process, this transaction will be canceled.',
        timeRemainingForDrawing: 'You have {timeRemaining} to complete this drawing entry.',
        minutes: ' minutes',
        registrant: 'Registrant',
        registrantInformation: 'Your Registration Information',
        nickname: 'Nintendo Account Nickname',
        confirmRSVP: 'Confirm',
        confirmEntry: 'Enter Drawing',
        legalConsent: 'Legal Consent',
        adultAcknowledgement:
            'By clicking this box I confirm that I am 18 or older and that I agree to the files included at the links below.',
        childAcknowledgement:
            'By clicking this box I confirm to be the parent/legal guardian of the following minors and I agree on their behalf to the files included at the links above:',
        event_registration: {
            attendees: 'Attendees',
            note: 'NOTE:',
            attendeeCannotRegister:
                'Attendee cannot register for more than one activity during the same time slot or the same activity on the same day.',
            currentUserIneligible:
                'You are already registered for a Warp Pipe Pass for this Activity or other Activity at the same time.',
            removedGuests:
                '{guests} has been removed as a guest because they have already registered for a Warp Pipe Pass for this Activity or another Activity at the same time.',
            selectAllAttendees: 'Select all attendees for this activity.',
        },
    },
    tickets: {
        ticket: 'ticket',
        tickets: 'tickets',
        wppRequiredForCheckIn: 'This {0} is required for check in.',
        wppsRequiredForCheckIn: 'These {0} are required for check in.',
        ticketRequiredForCheckIn: 'This {0} is required to attend the event.',
        ticketsRequiredForCheckIn: 'These {0} are required to attend the event.',
        getTickets: 'Get Tickets',
        eventTicket: 'Event Ticket',
        eventTickets: 'Event Tickets',
        viewTickets: 'View Tickets',
        viewEntry: 'View Entry',
        registrationClosed: 'Registration Closed',
        comingSoon: 'Coming Soon',
        cancelOtherTicket: 'Please cancel your other ticket before registering for this ticket.',
        soldOut: 'Sold Out',
        adultRequired: 'Adult Required',
        adultRequiredTooltip: 'This Event can only be registered by an adult.',
        adultRequiredActivityTooltip: 'This Activity can only be registered by an adult.',
        backToMyTicketsPasses: 'Back to My Tickets/Passes',
        registrationAlreadyClosed: 'Registration closed on {0} at {1}',
        registrationNotStarted: 'Registration Opens on {0} at {1}',
        notFound: 'Tickets not found',
        unknownTicketType: 'Unknown ticket type',
        consent: {
            consentRequiredTooltipTitle: 'Consent Required',
            consentRequiredTooltipBody:
                'This user must accept the invitation and agree to terms by logging in to their Nintendo Account.',
            consentRequiredToolTipBodyHostUrl: 'accept the event invitation',
            consentRequiredToolTipBodyHost:
                'You must {0} and agree to terms in order to attend this activity.',
        },
        receivedTickets: "You've received your tickets for {resourceName} {resourceDates}.",
        drawings: {
            wonDrawing: 'You won drawing tickets for {resourceName} {resourceDates}.',
            lostDrawing: "We're sorry but you did not win the drawing for this Ticket.",
            pendingDrawing:
                'You have entered the Ticket drawing for {resourceName} {resourceDates}.',
        },
        registration: {
            ticketDetails: 'Ticket Details',
            registrant: {
                onlyOneActivity:
                    '{note} Attendees cannot register for more than one activity during the same time slot or the same activity on the same day.',
                dataNotice: 'To learn more about how we use your data, please review our {link}',
                requiredFields: 'All fields marked with * are required',
            },
            guest: {
                guest: 'Guest',
                guests: 'Your Guests (Up to {maxGuests})',
                birthday: 'Birthday',
                addManageGuests: 'Add/Manage Guest(s)',
                manageNintendoAccountFamily: 'Manage Nintendo Account Family',
                guestsRemovedTicket:
                    '{guests} has been removed as a guest because they have already registered for a ticket for this event.',
                guestsRemovedDrawing:
                    '{guests} has been removed as a guest because they have already entered the drawing for a ticket for this event.',
                maxLimit: 'There is a max of {max} guest(s).',
                guestFamilyHelp: "Don't have a family group set up? {guestFamilyHelpLink}",
                guestFamilyHelpLink: 'Find out how to create and manage one.',
                guestFamilyHelpUrl:
                    'https://en-americas-support.nintendo.com/app/answers/detail/p/992/c/854/a_id/22324',
                guestFamilyWarning:
                    'Guests can only be added from your {nintendoFamilyLink}. If you do not see your guests listed, please add them to your Nintendo Account Family Group first.',
                guestFamilyLink: 'Nintendo Account Family Group',
                guestFamilyUrl:
                    'https://en-americas-support.nintendo.com/app/answers/detail/a_id/22324/~/how-to-add-members-to-a-nintendo-account-family-group',
                guestRemoveButtonLabel: `Remove {user} from party`,
                selectFamilyMember: 'Select from available Nintendo Account Family Members',
                counter: '{current} / {max} guest(s) selected',
                child: 'Child',
                notSelectableIf: 'Family Members are not selectable if',
                reachedMaxGuests: "You've reached the max limit for guests.",
                childAndNotParent: 'They are a child and you are not the parent/guardian.',
                alreadyRegistered: 'They are already registered.',
            },
        },
        cancelTicket: {
            cancelTicketModalTitle: 'Cancel Ticket',
            cancelLink: 'Cancel this ticket',
            cancelTicketModalWarning: {
                line1: 'You will be unable to attend this Event without a Ticket.',
                line2: 'Your Ticket and your Warp Pipe Pass(es) will be canceled.',
                line3: "Canceling your Ticket will automatically cancel everyone's Ticket and Warp Pipe Pass(es) in your group (if applicable). Everyone will receive a cancelation confirmation email.",
                line4: 'All cancellations are final. Tickets and Warp Pipe Passes will not be honored if canceled.',
            },
            areYouSure: 'Are you sure you want to cancel this ticket?',
            imSure: "I'm sure",
            cancelButton: 'Cancel Ticket',
            cancelYourTicket: 'Can’t make it? Cancel your ticket(s)',
        },
        cancelPass: {
            cancelTicketModalTitle: 'Cancel Pass',
            cancelLink: 'Cancel this pass',
            areYouSure: 'Are you sure you want to cancel this Warp Pipe Pass?',
            imSure: "I'm sure",
            cancelButton: 'Cancel Pass',
        },
        cancelDrawingEntry: {
            cancelTicketModalTitle: 'Cancel Entry',
            cancelLink: 'Cancel this entry',
            cancelTicketModalWarning: {
                line1: 'You will be removed from the drawing for this Ticket.',
                line2: "Cancelling your drawing entry will automatically cancel everyone's drawing entry in your group (if applicable).  Everyone will receive a cancellation confirmation email.",
                line3: '',
                line4: '',
            },
            areYouSure: 'Are you sure you want to cancel this Entry?',
            imSure: "I'm sure",
            cancelButton: 'Cancel Entry',
        },
        appleWalletAddButtonAlt: 'Apple Wallet Add Button',
    },
    terms: {
        acceptTerms: 'Accept Terms',
        acceptEventTerms: 'Accept Event Terms',
        addedToDrawing:
            'You have been added to a drawing to receive a ticket to the following Nintendo Event by {invitor}. In order to be eligible for the drawing you must accept the Event Terms.',
        addedToEvent:
            'You have received a ticket to the following Nintendo Event by {invitor}. In order to be eligible for the event you must accept the Event Terms.',
        completeInformation: 'Please complete the following information:',
        terms: 'terms',
        viewAndAcceptTerms: 'View and Accept Terms',
        pendingTermsConsent: 'Pending Terms Consent',
    },
    error: {
        error: 'Error',
        warning: 'Warning',
        modal: {
            close: 'Close',
        },
        notFound: {
            title: 'Sorry, but the page or event you are looking for was not found.',
            viewAvailableEvents: 'View Available Events',
        },
        eventsError: 'An error occurred while retrieving events. Please try again later.',
        eventDetailsError:
            'An error occurred while retrieving event details. Please try again later.',
        activityDetailsError:
            'An error occurred while retrieving activity details. Please try again later.',
        timeSlotError:
            'An error occurred while retrieving time slots information. Please try again later.',
        tempReservationError:
            'An error occurred while trying to hold this reservation. Please try again later.',
        myRSVPsError: 'An error occurred while retrieving your RSVPs. Please try again later.',
        mapError: 'The location map cannot be displayed right now.',
        dataCollectionError:
            'An error occurred while retrieving data attributes. Please try again later.',
        reservationCouldNotBeConfirmed:
            'The reservation could not be confirmed. Please try again later.',
        reservationCancelFailure: 'An error occurred during cancellation. Please try again later.',
        reservationFormError: 'All required fields need to be filled in.',
        eventServiceUnavailable: 'The system is currently unavailable. Please try again later.',
        rsvpTimeOut: "We're sorry but your registration has expired. Please try registering again.",
        ticketConfirmationFailed:
            'Event Ticket confirmation has failed. Please check the entered details and try again.',
        serviceError: {
            GP0009: 'No available spaces in requested time slot',
            GP0010: 'Already registered. You cannot register for more than one activity during the same time slot or the same activity on the same day.',
            GP0011: 'Invalid reservation key',
            GP0013: 'Registration for activity not yet started',
            GP0014: 'Registration for activity has ended',
            GP0015: 'Cannot find reservation to cancel',
            GP0016: 'Time slot has already ended',
            GP0243: 'You have already been added for a ticket to this event.',
            GP0244: 'You have already been entered into the drawing for this event.',
            GP0252: 'You already have a Warp Pipe Pass for this activity',
            unknown: 'Error occurred',
        },
        serviceErrorModal: {
            GP212: {
                title: 'Registration Not Open',
                body: 'Registration for this ticket is not yet open.',
            },
            GP213: {
                title: 'Registration Closed',
                body: 'Registration for this ticket has closed.',
            },
            GP0214: {
                title: 'Event Ticket Unavailable',
                body: 'Sorry, the Event Ticket you have selected has sold out. Please check for other ticket availability.',
            },
            GP0218: {
                title: 'Ineligible',
                body: 'You are already entered into a drawing for this ticket.',
            },
            GP220: {
                title: 'Registration Not Open',
                body: 'Registration for this ticket is not yet open.',
            },
            GP221: {
                title: 'Registration Closed',
                body: 'Registration for this ticket has closed.',
            },
            unknown: {
                title: 'Unknown Error',
                body: 'Sorry, but there was an unknown error. Please try again.',
            },
        },
        formError: {
            required: '{attribute} is required.',
            default: '{attribute} is invalid.',
        },
        drawingCancelingError: 'Error canceling drawing',
        ticketCancelingError: 'Error canceling ticket',
        timeSlotCancelingError: 'Error canceling pass',
        minNumberOfGuestsError:
            'Please add {atLeastNum} Nintendo Account family group member(s) in order to register.',
        atLeast: 'at least {expectedNumberOfGuests}',
    },
    drawing: {
        drawing: 'Drawing',
        firstComeFirstServed: 'First Come, First Served',
        enterForAChance: 'Enter for a Chance to Participate',
        youHaveAlreadyEntered: 'You have already entered the drawing for this activity.',
        drawingRules: 'Drawing Rules',
        ruleX: 'Rule: {rule}',
        status: {
            pending: 'Pending Drawing ({partySize} {type})',
            notSelected: 'You were not selected in this drawing',
            notWinner: 'Not a Winner',
            winner: 'Drawing Winner',
        },
    },
    account: {
        accountRequired: 'Nintendo Account Required',
        pleaseLogin: {
            basic: 'Please {0} to your Nintendo Account',
            toView: 'Please {0} to your Nintendo Account to view your {1}.',
            toAccept: 'Please {0} to your Nintendo Account to accept our terms',
        },
        requiredToRSVP:
            'A Nintendo Account is required to register. Please sign in or create a free account to continue.',
        rsvpWillBeHeld:
            "We'll hold your RSVP for the next {0} after you sign in so you can complete pre-registration.",
        time: '10 minutes',
        checkIn: {
            title: 'Check-in',
            presentCode: 'Present this {0} to check in at select events and locations.',
            poorInternetConnectivity:
                'In case there is poor internet connectivity at your event, we recommend that you print out this pass either from this page or from the email we have sent to the email address associated with your Nintendo Account. (if you do not see this email, please check your SPAM folder).',
            checkInCode: 'Check In code',
            appleWalletTM:
                'Apple Wallet is a trademark of Apple Inc., registered in the U.S. and other countries and regions.',
        },
        passes: {
            warpPipePass: 'Warp Pipe Pass',
            warpPipePasses: 'Warp Pipe Passes',
        },
        rsvps: {
            activeRSVPs: 'Active RSVPs',
            drawingEntries: 'Drawing Entries',
            rsvps: 'Tickets and Warp Pipe Passes',
            viewPass: 'View Pass',
            reservationUpdated: '{0} has been updated.',
            reservationRemoved: '{0} has been removed.',
            ticketAdded: 'You have successfully received your {ticketName}.',
            drawingTicketAdded: 'You have successfully entered the drawing for {ticketName}.',
            warpPipePassAdded: 'You have successfully received your pass for {activityName}.',
            edit: {
                title: 'Change Time',
            },
            cancel: {
                title: 'Cancel RSVP',
                entry: 'Cancel Entry',
                pass: 'Cancel Pass',
                ticket: 'Cancel Ticket',
                areYouSure: 'Are you sure you want to cancel this {0}?',
            },
            details: {
                pass: {
                    ios: require('../images/pass/ios/en/apple_wallet_badge_en.png'),
                },
                noneFound: 'No valid RSVP found',
                requiredForCheckIn: 'This {0} is required for check in.',
                addToCalendar: 'Add to Calendar',
            },
        },
    },
    noneFound: {
        events: 'There are currently no events available for registration. Please check back at a later time. Thank you.',
        activities: 'No activities are currently available for this event.',
        timeSlots: 'No time slots were found for this activity.',
        reservations: 'You currently do not have any RSVPs.',
        warpPipePasses: 'You currently do not have any active Warp Pipe Passes',
        tickets: 'You currently do not have any active Event Tickets',
    },
    footer: {
        disclaimer:
            'Pass and/or Ticket is one-time use only, non-transferable and cannot be sold. Pass and/or Ticket may be redeemable for priority access to wait in line for select game or event at designated time and may not be redeemable if used during non-designated time. QR Code is a registered trademark of DENSO WAVE INCORPORATED. Nintendo trademarks are properties of Nintendo. © Nintendo.',
        privacyPolicy: 'Privacy Policy',
        privacyPolicyUrl: 'https://www.nintendo.com/privacy-policy/',
        termsOfUse: 'Terms of Use',
        termsOfUseUrl: 'https://www.nintendo.com/terms-of-use/',
    },
    dates: {
        day: 'DD',
        dayOfWeekShort: 'ddd',
        dayOfWeek: 'dddd',
        fullDate: 'ddd MMMM DD, YYYY - h:mma',
        dateRangeFirst: 'MMM DD',
        dateRangeSecond: 'MMM DD, YYYY',
        hourMinute: 'h:mma',
        hourMinutesTimezone: 'h:mma zz',
        month: 'MMM',
        monthDateYear: 'MMM DD, YYYY',
        monthDateYearLong: 'MMMM DD, YYYY',
        range: ' - ',
        reservationDate: 'dddd, MMM D, YYYY',
        reservationDateShort: 'ddd, MMM D, YYYY',
        reservationTime: 'h:mm A z',
        registrationDate: 'ddd MMM DD, YYYY hh:mma z',
        timeSlot: {
            timeSlotHourMinuteTimezone: 'hh:mm A z',
            timeSlotHourMinute: 'hh:mm A',
            timeSlotTop: 'ddd',
            timeSlotMiddle: 'DD',
            timeSlotBottom: 'MMM',
        },
        eventTickets: {
            dateRange: 'ddd, MMM DD, YYYY',
            time: 'ha',
            same_day_time: 'hh:mm a',
            timeZone: 'zz',
            cancel: {
                time: 'dddd MMM D, YYYY - hh:mm a',
            },
        },
        birthday: 'MMMM D, YYYY',
    },
    countries: {
        title: 'Country: {0}',
        values: {
            ALL: 'All',
            US: 'United States',
            CA: 'Canada',
            AU: 'Australia',
        },
    },
    files: {
        fileCategory: {
            GENERAL_INFO: 'General Information',
            LEGAL: 'Legal',
            TOURNAMENT_INFO: 'Tournament Information',
        },
    },
};

export type PublicLocalizationObject = typeof en;

export default en;
